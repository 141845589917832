import { useMenu } from "../../contexts/menuContext";
import { ButtonToggleMenuStyle } from "./styles";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import theme from "../../theme";

export const ButtonToggleMenu = () => {
    const { isMenuOpen, toggleMenu } = useMenu();

    return (
        <ButtonToggleMenuStyle isOpen={isMenuOpen}>
            {isMenuOpen ? (
                <BsChevronDoubleLeft title="Minimizar menu" className="collapse-menu" size={14} color={theme.colors.blue} onClick={toggleMenu} />
            ):(
                <>
                <BsChevronDoubleRight title="Expandir menu" className="expand-menu" size={14} color={theme.colors.blue} onClick={toggleMenu} />
                </>
            )}
        </ButtonToggleMenuStyle>
    );
};