import Parser from "html-react-parser";
import Select from "react-select";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { formatInputNumber } from "../../../../../utils/utils";
import useAuth from "../../../../../hooks/useAuth";
import { Container, TableContainer } from "./styles";
import { formatPrice3,formatPrice5, formatPrice, formatPrice17 } from "../../../../../utils/transform";
import inventoryService from "../../../../../services/inventoryService";
import scope3Service from "../../../../../services/scope3Service";
import scopeService from "../../../../../services/scopeService";
import TrashIcon from "../../../../../assets/icons/trash.svg";
import { Button } from "../../../../../components/Button/styles";
import { Modal } from "../../../../../components/Modal";
import { GoBack } from "../../../../../components/GoBack";
import { TitleText } from "../../../../../components/TitleText";
import ReportRecordsCheckbox from "../../../components/ReportRecordsCheckbox";
import ScopeEvidenceAttachments from "../../../components/ScopeEvidenceAttachments";
import { Uninformed } from "../../../../../components/Uninformed";
import { toast } from "react-hot-toast";
import homeOfficeService from "../../../../../services/homeOfficeService";
import { ModalImportData } from "../../../../../components/ModalImportData";
import utilsService from "../../../../../services/utilsService";
import { Loading } from "../../../../../components/Loading";
import { EletricityParams } from "../../../../../interfaces/ParamEmissonGeneric";
import { ModalDeleteDataTable } from "../../../../../components/ModalDeleteDataTable";
import { HomeOfficeSubHeader } from "../../../../../components/Scope3/HomeWorkDisplacement/HomeOffice/HomeOfficeSubHeader/index"
import { FooterResultTable } from "../../../../../components/FooterResultTable/index"
import { HomeOfficeTable } from "./Tables/modelIngee/index";
import { TableGHGHomeOffice } from "./Tables/modelGhg";
import ModalHandleImportData from "./Tables/ModalImportData/";
import { useMenu } from "../../../../../contexts/menuContext";

export const HomeWorkHomeOffice = () => {
  const history = useHistory();
  const { isMenuOpen } = useMenu();

  const [files, setFiles] = useState<any>([]);
  const [fileT3, setFileT3] = useState<File | any>(null);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>();

  const [importErrors, setImportErrors] = useState<any>([]);
  const [openModalError, setOpenModalError] = useState(false);
  const [openModalImport, setOpenModalImport] = useState(false);
  const [openModalImportT2, setOpenModalImportT2] = useState(false);
  const [openModalImportT3, setOpenModalImportT3] = useState(false);
  const [totalAllTablesEmissionCo2, setTotalAllTablesEmissionCo2] = useState<any>();

  // Items for table 1:
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [ignoreFill, setIgnoreFill] = useState(true);
  const [homeOffice, setHomeOffice] = useState<any>([]);
  const [homeEnergy, setHomeEnergy] = useState<any>([]);
  const [purchaseJan, setPurchaseJan] = useState<any>();
  const [purchaseFeb, setPurchaseFeb] = useState<any>();
  const [purchaseMar, setPurchaseMar] = useState<any>();
  const [purchaseApr, setPurchaseApr] = useState<any>();
  const [purchaseMay, setPurchaseMay] = useState<any>();
  const [purchaseJun, setPurchaseJun] = useState<any>();
  const [purchaseJul, setPurchaseJul] = useState<any>();
  const [purchaseAug, setPurchaseAug] = useState<any>();
  const [purchaseSep, setPurchaseSep] = useState<any>();
  const [purchaseOct, setPurchaseOct] = useState<any>();
  const [purchaseNov, setPurchaseNov] = useState<any>();
  const [purchaseDec, setPurchaseDec] = useState<any>();
  const [emissionCo2, setEmissionCo2] = useState<any>();
  const [emissionCh4, setEmissionCh4] = useState<any>();
  const [emissionN2o, setEmissionN2o] = useState<any>();
  const [loadingModal, setLoadingModal] = useState(false);
  const [purchaseTotal, setPurchaseTotal] = useState<any>();
  const [purchaseYearly, setPurchaseYearly] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [inventorySelected, setInventorySelected] = useState<any>({});
  const [changeHomeOffice, setChangeHomeOffice] = useState<string | undefined>();

  const [openModalDelete, setOpenModalDelete] = useState(false);

  // Data for Table 2 Ingee model:
  const [ignoreFillT2, setIgnoreFillT2] = useState(true);
  const [homeOfficeT2, setHomeOfficeT2] = useState<any>([]);
  const [homeEnergyT2, setHomeEnergyT2] = useState<any>([]);
  const [purchaseJanT2, setPurchaseJanT2] = useState<any>();
  const [purchaseFebT2, setPurchaseFebT2] = useState<any>();
  const [purchaseMarT2, setPurchaseMarT2] = useState<any>();
  const [purchaseAprT2, setPurchaseAprT2] = useState<any>();
  const [purchaseMayT2, setPurchaseMayT2] = useState<any>();
  const [purchaseJunT2, setPurchaseJunT2] = useState<any>();
  const [purchaseJulT2, setPurchaseJulT2] = useState<any>();
  const [purchaseAugT2, setPurchaseAugT2] = useState<any>();
  const [purchaseSepT2, setPurchaseSepT2] = useState<any>();
  const [purchaseOctT2, setPurchaseOctT2] = useState<any>();
  const [purchaseNovT2, setPurchaseNovT2] = useState<any>();
  const [purchaseDecT2, setPurchaseDecT2] = useState<any>();
  const [emissionCo2T2, setEmissionCo2T2] = useState<any>();
  const [emissionCh4T2, setEmissionCh4T2] = useState<any>();
  const [emissionN2oT2, setEmissionN2oT2] = useState<any>();
  const [purchaseTotalT2, setPurchaseTotalT2] = useState<any>();
  const [purchaseYearlyT2, setPurchaseYearlyT2] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelectedT2, setScopeSelectedT2] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApiT2, setErrorApiT2] = useState<string | undefined>();
  const [inventorySelectedT2, setInventorySelectedT2] = useState<any>({});
  const [changeHomeOfficeT2, setChangeHomeOfficeT2] = useState<string | undefined>();

  const [openModalDeleteTT2, setOpenModalDeleteTT2] = useState(false);

  // Data for Table 3 GHG Model:
  const [homeOfficeT3, setHomeOfficeT3] = useState<any>([]);
  const [homeEnergyT3, setHomeEnergyT3] = useState<any>([]);
  const [emissionCo2T3, setEmissionCo2T3] = useState<number>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelectedT3, setScopeSelectedT3] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inventorySelectedT3, setInventorySelectedT3] = useState<any>({});
  const [changeHomeOfficeT3, setChangeHomeOfficeT3] = useState<string | undefined>();


  useEffect(() => {
    const item: any = location?.state;

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope);
    } else {
      history.push({
        pathname: "/inventario/escopo-3",
      });
    };
    sumTotalAllTablesEmissionCo2()
    getGuidelines();
  },// eslint-disable-next-line react-hooks/exhaustive-deps
    [location]);    

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(
      3,
      "deslocamento_casa_trabalho",
      "home_office"
    );

    setTitle(item.label);
    setGuidelines(item.guidelines);
  }

  function handleOpenInfo() {
    setOpenModal(!openModal);
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true);

    if (formData) {

      if(openModalDelete){
        setLoadingModal(false);
        return;
      }
      
      const homeOfficeAutomatico: any[] = homeOffice.map((scope: any, index: number) => {
        return {
          city: scope.city,
          state: scope.state,
          source: scope.source,
          description: scope.description,
          total_days_year : scope.total_days_year,
          utils_day_work_home : scope.utils_day_work_home ,
          average_consume : scope.average_consume,
          private_name: scope.private_name,
          company_name: scope.company_name,
          purchase_jan: scope.purchase_jan ? scope.purchase_jan : 0,
          purchase_feb: scope.purchase_feb ? scope.purchase_feb : 0,
          purchase_mar: scope.purchase_mar ? scope.purchase_mar : 0,
          purchase_apr: scope.purchase_apr ? scope.purchase_apr : 0,
          purchase_may: scope.purchase_may ? scope.purchase_may : 0,
          purchase_jun: scope.purchase_jun ? scope.purchase_jun : 0,
          purchase_jul: scope.purchase_jul ? scope.purchase_jul : 0,
          purchase_aug: scope.purchase_aug ? scope.purchase_aug : 0,
          purchase_sep: scope.purchase_sep ? scope.purchase_sep : 0,
          purchase_oct: scope.purchase_oct ? scope.purchase_oct : 0,
          purchase_nov: scope.purchase_nov ? scope.purchase_nov : 0,
          purchase_dec: scope.purchase_dec ? scope.purchase_dec : 0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          purchase_total: scope.purchase_total ? scope.purchase_total : 0,
          purchase_yearly: scope.purchase_yearly ? scope.purchase_yearly : 0,
          emission_co2_jan: scope.emission_co2_jan ? scope.emission_co2_jan : 0,
          emission_co2_feb: scope.emission_co2_feb ? scope.emission_co2_feb : 0,
          emission_co2_mar: scope.emission_co2_mar ? scope.emission_co2_mar : 0,
          emission_co2_apr: scope.emission_co2_apr ? scope.emission_co2_apr : 0,
          emission_co2_may: scope.emission_co2_may ? scope.emission_co2_may : 0,
          emission_co2_jun: scope.emission_co2_jun ? scope.emission_co2_jun : 0,
          emission_co2_jul: scope.emission_co2_jul ? scope.emission_co2_jul : 0,
          emission_co2_aug: scope.emission_co2_aug ? scope.emission_co2_aug : 0,
          emission_co2_sep: scope.emission_co2_sep ? scope.emission_co2_sep : 0,
          emission_co2_oct: scope.emission_co2_oct ? scope.emission_co2_oct : 0,
          emission_co2_nov: scope.emission_co2_nov ? scope.emission_co2_nov : 0,
          emission_co2_dec: scope.emission_co2_dec ? scope.emission_co2_dec : 0,
        };
      });

      const homeOfficeManual: any[] = homeOfficeT2.map((scope: any, index: number) => {
        return {
          city: scope.city,
          state: scope.state,
          source: scope.source,
          description: scope.description,
          total_days_year : scope.total_days_year,
          utils_day_work_home : scope.utils_day_work_home ,
          average_consume : scope.average_consume,
          private_name: scope.private_name,
          company_name: scope.company_name,
          purchase_jan: scope.purchase_jan ? scope.purchase_jan : 0,
          purchase_feb: scope.purchase_feb ? scope.purchase_feb : 0,
          purchase_mar: scope.purchase_mar ? scope.purchase_mar : 0,
          purchase_apr: scope.purchase_apr ? scope.purchase_apr : 0,
          purchase_may: scope.purchase_may ? scope.purchase_may : 0,
          purchase_jun: scope.purchase_jun ? scope.purchase_jun : 0,
          purchase_jul: scope.purchase_jul ? scope.purchase_jul : 0,
          purchase_aug: scope.purchase_aug ? scope.purchase_aug : 0,
          purchase_sep: scope.purchase_sep ? scope.purchase_sep : 0,
          purchase_oct: scope.purchase_oct ? scope.purchase_oct : 0,
          purchase_nov: scope.purchase_nov ? scope.purchase_nov : 0,
          purchase_dec: scope.purchase_dec ? scope.purchase_dec : 0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          purchase_total: scope.purchase_total ? scope.purchase_total : 0,
          purchase_yearly: scope.purchase_yearly ? scope.purchase_yearly : 0,
          emission_co2_jan: scope.emission_co2_jan ? scope.emission_co2_jan : 0,
          emission_co2_feb: scope.emission_co2_feb ? scope.emission_co2_feb : 0,
          emission_co2_mar: scope.emission_co2_mar ? scope.emission_co2_mar : 0,
          emission_co2_apr: scope.emission_co2_apr ? scope.emission_co2_apr : 0,
          emission_co2_may: scope.emission_co2_may ? scope.emission_co2_may : 0,
          emission_co2_jun: scope.emission_co2_jun ? scope.emission_co2_jun : 0,
          emission_co2_jul: scope.emission_co2_jul ? scope.emission_co2_jul : 0,
          emission_co2_aug: scope.emission_co2_aug ? scope.emission_co2_aug : 0,
          emission_co2_sep: scope.emission_co2_sep ? scope.emission_co2_sep : 0,
          emission_co2_oct: scope.emission_co2_oct ? scope.emission_co2_oct : 0,
          emission_co2_nov: scope.emission_co2_nov ? scope.emission_co2_nov : 0,
          emission_co2_dec: scope.emission_co2_dec ? scope.emission_co2_dec : 0,
        };
      });

      const homeOfficeGHG: any[] = homeOfficeT3.map((scope: any, index: number) => {
        return {
          city: scope.city,
          state: scope.state,
          source: scope.source,
          description: scope.description,
          private_name: scope.private_name,
          company_name: scope.company_name,
          number_employees: scope.number_employees | 0,
          days_worked_home_office: scope.days_worked_home_office | 0,
          average_consumption_employee: scope.average_consumption_employee | 0,
          days_worked_year: scope.days_worked_year | 0,
          average_consumption_electricity: scope.average_consumption_electricity ? scope.average_consumption_electricity : 0.0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0.0,
        }
      })

      const scope = {
        status: 2,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        itens: [{home_office_manual: homeOfficeManual, home_office_automatico: homeOfficeAutomatico, home_office_ghg: homeOfficeGHG}]
      };

      const result = await scope3Service.saveHomeWorkDisplacementHomeOffice(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      );
      
      if (result && !result.error) {
        localStorage.setItem('HomeWorkDisplacementHomeOffice', JSON.stringify(scope));

        setErrorApi(undefined);

        toast.success("Operação realizada com sucesso!");
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }

    setLoadingModal(false);
  };

  const { isCommonUser } = useAuth();

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true);

    const homeOfficeOptions = await homeOfficeService.getByParams({
      limit: 9000,
      page: 1,
    });

    const options = homeOfficeOptions?.itens.map((item: any) => {
      return {
        label:
          item.name +
          " (" +
          (item.is_company
            ? item.company_name
            : item.city + " - " + item.state) +
          ")",
        city: item.city,
        state: item.state,
        value: item.id,
      };
    });

    setHomeEnergy(options);
    setHomeEnergyT2(options);
    setHomeEnergyT3(options);

    const inventory = await inventoryService.getById(company_id, year);
    const entity = await scope3Service.getHomeWorkDisplacementHomeOffice(company_id,year);

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      "deslocamento_casa_trabalho",
      "home_office"
    );
    setFiles(result.files);

    if (entity) {
      setValue("reason", entity.reason);
      setValue("evidence", entity.evidence);
      setValue("ignore_fill", entity.ignore_fill);

      setScopeSelected(entity);
      setScopeSelectedT2(entity);
      setScopeSelectedT3(entity);

      for (const item of entity?.itens[0].home_office_manual) {
        item.only_view_yearly =
          item.purchase_jan > 0 ||
          item.purchase_feb > 0 ||
          item.purchase_mar > 0 ||
          item.purchase_apr > 0 ||
          item.purchase_may > 0 ||
          item.purchase_jun > 0 ||
          item.purchase_jul > 0 ||
          item.purchase_aug > 0 ||
          item.purchase_sep > 0 ||
          item.purchase_oct > 0 ||
          item.purchase_nov > 0 ||
          item.purchase_dec > 0;

        item.only_view_monthly = item.purchase_yearly > 0;
      };
      
      for (const item of entity?.itens[0].home_office_automatico) {
        item.only_view_yearly =
          item.purchase_jan > 0 ||
          item.purchase_feb > 0 ||
          item.purchase_mar > 0 ||
          item.purchase_apr > 0 ||
          item.purchase_may > 0 ||
          item.purchase_jun > 0 ||
          item.purchase_jul > 0 ||
          item.purchase_aug > 0 ||
          item.purchase_sep > 0 ||
          item.purchase_oct > 0 ||
          item.purchase_nov > 0 ||
          item.purchase_dec > 0;

        item.only_view_monthly = item.purchase_yearly > 0;
      };

      setScopeValues(entity?.itens[0].home_office_automatico);
      calculateTotals(entity?.itens[0].home_office_automatico);
      
      setScopeValuesT2(entity?.itens[0].home_office_manual);
      calculateTotalsT2(entity?.itens[0].home_office_automatico);

      setScopeValuesT3(entity?.itens[0].home_office_ghg);
      calculateTotalsT3(entity?.itens[0].home_office_ghg);

      sumTotalAllTablesEmissionCo2(entity?.itens[0]);


      setIgnoreFill(entity.ignore_fill);
      setIgnoreFillT2(entity.ignore_fill);

      setChangeHomeOffice(
        "load_scope_" + entity.id + "_scopes_" + entity?.itens[0].home_office_automatico.length
      );

      setChangeHomeOfficeT2(
        "load_scope_" + entity.id + "_scopes_" + entity?.itens[0].home_office_manual.length
      );

      setChangeHomeOfficeT2(
        "load_scope_" + entity.id + "_scopes_" + entity?.itens[0].home_office_ghg.length
      );
    } else {
      setScopeSelected({});
      setIgnoreFill(false);

      setScopeValues([]);
      calculateTotals([]);
      calculateTotalsT2([]);
      calculateTotalsT3();
      sumTotalAllTablesEmissionCo2();

      setChangeHomeOffice("load_scope_0_scopes_0");
    }

    setInventorySelected(inventory);
    setInventorySelectedT2(inventory);
    setInventorySelectedT3(inventory);
    setLoadingModal(false);
  }

  function handleDeleteHomeOffice(index: number) {
    const itens = [];

    for (let i = 0; i < homeOffice?.length; i++) {
      if (i !== index) {
        itens.push(homeOffice[i]);
      }
    }

    setChangeHomeOffice("remove_scope_item_" + index);

    setValue("energyTypes", []);

    setScopeValues(itens);
    calculateTotals(itens);
  }

  function handleDeleteHomeOfficeT2(index: number) {
    const itens = [];

    for (let i = 0; i < homeOfficeT2?.length; i++) {
      if (i !== index) {
        itens.push(homeOfficeT2[i]);
      }
    }

    setChangeHomeOfficeT2("remove_scope_item_" + index);

    setValue("energyTypes", []);

    setScopeValuesT2(itens);
    calculateTotalsT2(itens);
  };

  function handleDeleteHomeOfficeT3(index: number) {
    const itens = [];

    for (let i = 0; i < homeOfficeT3?.length; i++) {
      if (i !== index) {
        itens.push(homeOfficeT3[i]);
      }
    }

    setChangeHomeOfficeT3("remove_scope_item_" + index);

    setValue("energyTypes", []);

    setScopeValuesT3(itens);
    calculateTotalsT3();
  };

  function handleOpenModalDelete(){
    setOpenModalDelete(true);
  }

  function handleDeleteAllScope(){
    const itens: any = [];

    setChangeHomeOffice('remove_Scope_item');
    setValue('energyTypes', []);

    setScopeValues(itens);
    calculateTotals(itens);

    setOpenModalDelete(false);
  }

  function handleDeleteAllScopeT2(){
    const itens: any = [];

    setChangeHomeOfficeT2('remove_Scope_item');
    setValue('energyTypesT2', []);

    setScopeValuesT2(itens);
    calculateTotalsT2(itens);

    setOpenModalDelete(false);
  }

  function handleDeleteAllScopeT3(){   
    const itens: any = [];

    setChangeHomeOfficeT3('remove_Scope_item');
    setValue('energyTypesT3', []);

    setScopeValuesT3(itens);
    // calculateTotals(itens);

    setOpenModalDelete(false);
  }

  const calculateTotals = (scopes: any) => {
    let purchaseJan = 0;
    let purchaseFeb = 0;
    let purchaseMar = 0;
    let purchaseApr = 0;
    let purchaseMay = 0;
    let purchaseJun = 0;
    let purchaseJul = 0;
    let purchaseAug = 0;
    let purchaseSep = 0;
    let purchaseOct = 0;
    let purchaseNov = 0;
    let purchaseDec = 0;
    let emissionCo2 = 0;
    let emissionCh4 = 0;
    let emissionN2o = 0;
    let purchaseTotal = 0;
    let purchaseYearly = 0;

    if (scopes) {
      for (const item of scopes) {
        if (item) {
          if (item.purchase_jan) {
            purchaseJan = purchaseJan + item.purchase_jan;
          }
          if (item.purchase_feb) {
            purchaseFeb = purchaseFeb + item.purchase_feb;
          }
          if (item.purchase_mar) {
            purchaseMar = purchaseMar + item.purchase_mar;
          }
          if (item.purchase_apr) {
            purchaseApr = purchaseApr + item.purchase_apr;
          }
          if (item.purchase_may) {
            purchaseMay = purchaseMay + item.purchase_may;
          }
          if (item.purchase_jun) {
            purchaseJun = purchaseJun + item.purchase_jun;
          }
          if (item.purchase_jul) {
            purchaseJul = purchaseJul + item.purchase_jul;
          }
          if (item.purchase_aug) {
            purchaseAug = purchaseAug + item.purchase_aug;
          }
          if (item.purchase_sep) {
            purchaseSep = purchaseSep + item.purchase_sep;
          }
          if (item.purchase_oct) {
            purchaseOct = purchaseOct + item.purchase_oct;
          }
          if (item.purchase_nov) {
            purchaseNov = purchaseNov + item.purchase_nov;
          }
          if (item.purchase_dec) {
            purchaseDec = purchaseDec + item.purchase_dec;
          }
          if (item.emission_co2) {
            emissionCo2 = emissionCo2 + item.emission_co2;
          }
          if (item.emission_ch4) {
            emissionCh4 = emissionCh4 + item.emission_ch4;
          }
          if (item.emission_n2o) {
            emissionN2o = emissionN2o + item.emission_n2o;
          }
          if (item.purchase_yearly) {
            purchaseYearly = purchaseYearly + item.purchase_yearly;
          }
          if (item.purchase_total) {
            purchaseTotal = purchaseYearly +
            purchaseJan + purchaseFeb + purchaseMar +
            purchaseApr + purchaseMay + purchaseJun +
            purchaseJul + purchaseAug + purchaseSep +
            purchaseOct + purchaseNov + purchaseDec;
          }
        }
      }
    }

    setPurchaseJan(purchaseJan);
    setPurchaseFeb(purchaseFeb);
    setPurchaseMar(purchaseMar);
    setPurchaseApr(purchaseApr);
    setPurchaseMay(purchaseMay);
    setPurchaseJun(purchaseJun);
    setPurchaseJun(purchaseJul);
    setPurchaseJul(purchaseJul);
    setPurchaseAug(purchaseAug);
    setPurchaseSep(purchaseSep);
    setPurchaseOct(purchaseOct);
    setPurchaseNov(purchaseNov);
    setPurchaseDec(purchaseDec);
    setEmissionCo2(emissionCo2);
    setEmissionCh4(emissionCh4);
    setEmissionN2o(emissionN2o);
    setPurchaseTotal(purchaseTotal);
    setPurchaseYearly(purchaseYearly);
  };

  const calculateTotalsT2 = (scopes: any) => {
    let purchaseJan = 0;
    let purchaseFeb = 0;
    let purchaseMar = 0;
    let purchaseApr = 0;
    let purchaseMay = 0;
    let purchaseJun = 0;
    let purchaseJul = 0;
    let purchaseAug = 0;
    let purchaseSep = 0;
    let purchaseOct = 0;
    let purchaseNov = 0;
    let purchaseDec = 0;
    let emissionCo2 = 0;
    let emissionCh4 = 0;
    let emissionN2o = 0;
    let purchaseTotal = 0;
    let purchaseYearly = 0;

    if (scopes) {
      for (const item of scopes) {
        if (item) {
          if (item.purchase_jan) {
            purchaseJan = purchaseJan + item.purchase_jan;
          }
          if (item.purchase_feb) {
            purchaseFeb = purchaseFeb + item.purchase_feb;
          }
          if (item.purchase_mar) {
            purchaseMar = purchaseMar + item.purchase_mar;
          }
          if (item.purchase_apr) {
            purchaseApr = purchaseApr + item.purchase_apr;
          }
          if (item.purchase_may) {
            purchaseMay = purchaseMay + item.purchase_may;
          }
          if (item.purchase_jun) {
            purchaseJun = purchaseJun + item.purchase_jun;
          }
          if (item.purchase_jul) {
            purchaseJul = purchaseJul + item.purchase_jul;
          }
          if (item.purchase_aug) {
            purchaseAug = purchaseAug + item.purchase_aug;
          }
          if (item.purchase_sep) {
            purchaseSep = purchaseSep + item.purchase_sep;
          }
          if (item.purchase_oct) {
            purchaseOct = purchaseOct + item.purchase_oct;
          }
          if (item.purchase_nov) {
            purchaseNov = purchaseNov + item.purchase_nov;
          }
          if (item.purchase_dec) {
            purchaseDec = purchaseDec + item.purchase_dec;
          }
          if (item.emission_co2) {
            emissionCo2 = emissionCo2 + item.emission_co2;
          }
          if (item.emission_ch4) {
            emissionCh4 = emissionCh4 + item.emission_ch4;
          }
          if (item.emission_n2o) {
            emissionN2o = emissionN2o + item.emission_n2o;
          }
          if (item.purchase_yearly) {
            purchaseYearly = purchaseYearly + item.purchase_yearly;
          }
          if (item.purchase_total) {
            purchaseTotal = purchaseYearly +
            purchaseJan + purchaseFeb + purchaseMar +
            purchaseApr + purchaseMay + purchaseJun +
            purchaseJul + purchaseAug + purchaseSep +
            purchaseOct + purchaseNov + purchaseDec;
          }
        }
      }
    }

    setPurchaseJanT2(purchaseJan);
    setPurchaseFebT2(purchaseFeb);
    setPurchaseMarT2(purchaseMar);
    setPurchaseAprT2(purchaseApr);
    setPurchaseMayT2(purchaseMay);
    setPurchaseJunT2(purchaseJun);
    setPurchaseJunT2(purchaseJul);
    setPurchaseJulT2(purchaseJul);
    setPurchaseAugT2(purchaseAug);
    setPurchaseSepT2(purchaseSep);
    setPurchaseOctT2(purchaseOct);
    setPurchaseNovT2(purchaseNov);
    setPurchaseDecT2(purchaseDec);
    setEmissionCo2T2(emissionCo2);
    setEmissionCh4T2(emissionCh4);
    setEmissionN2oT2(emissionN2o);
    setPurchaseTotalT2(purchaseTotal);
    setPurchaseYearlyT2(purchaseYearly);
  };

  const calculateAverageConsumptionEletricity = (index: number) => {
    // (N colaba X Dias na semana trab X Dias no ano trab) / (5 X N Colab * 8/10^8)
    let numberStaff = parseFloat(homeOfficeT3[index].number_employees) | 0;
    let daysWorkedWeek = parseFloat(homeOfficeT3[index].days_worked_home_office) | 0;
    let daysWorkedYear = parseFloat(homeOfficeT3[index].days_worked_year) | 0;
    let averageConsumptionEmployee = parseFloat(homeOfficeT3[index].average_consumption_employee) | 0;
    const total = parseFloat(`${(numberStaff * averageConsumptionEmployee) * (daysWorkedYear / 5) * ((daysWorkedWeek * 8)/1000000)}`);
    return total;
  };

  const calculateTotalsT3 = (scope?: any) => {
    if(scope){
      const totalEmissionCo2 = scope.reduce((accumulator: number, currentItem: any) => {
        if (currentItem && currentItem.emission_co2) {
          return accumulator + currentItem.emission_co2;
        }
        return accumulator;
      }, 0);
  
      setEmissionCo2T3(totalEmissionCo2);
      return totalEmissionCo2;
    } else {
      const totalEmissionCo2 = homeOfficeT3.reduce((accumulator: number, currentItem: any) => {
        if (currentItem && currentItem.emission_co2) {
          return accumulator + currentItem.emission_co2;
        }
        return accumulator;
      }, 0);
  
      setEmissionCo2T3(totalEmissionCo2);
      return totalEmissionCo2;
    }
  };

  const handleChangeValue = (index: number, ignore_field?: string) => {
    const utils_day_work_home  = parseFloat(
      homeOffice[index].utils_day_work_home ? homeOffice[index].utils_day_work_home  : 0
    );
    const average_consume  = parseFloat(
      homeOffice[index].average_consume ? homeOffice[index].average_consume  : 0
    );
    const total_days_year  = parseFloat(
      homeOffice[index].total_days_year ? homeOffice[index].total_days_year   : 0
    );
    const purchaseJan = parseFloat(
      homeOffice[index].purchase_jan ? homeOffice[index].purchase_jan : 0
    );
    const purchaseFeb = parseFloat(
      homeOffice[index].purchase_feb ? homeOffice[index].purchase_feb : 0
    );
    const purchaseMar = parseFloat(
      homeOffice[index].purchase_mar ? homeOffice[index].purchase_mar : 0
    );
    const purchaseApr = parseFloat(
      homeOffice[index].purchase_apr ? homeOffice[index].purchase_apr : 0
    );
    const purchaseMay = parseFloat(
      homeOffice[index].purchase_may ? homeOffice[index].purchase_may : 0
    );
    const purchaseJun = parseFloat(
      homeOffice[index].purchase_jun ? homeOffice[index].purchase_jun : 0
    );
    const purchaseJul = parseFloat(
      homeOffice[index].purchase_jul ? homeOffice[index].purchase_jul : 0
    );
    const purchaseAug = parseFloat(
      homeOffice[index].purchase_aug ? homeOffice[index].purchase_aug : 0
    );
    const purchaseSep = parseFloat(
      homeOffice[index].purchase_sep ? homeOffice[index].purchase_sep : 0
    );
    const purchaseOct = parseFloat(
      homeOffice[index].purchase_oct ? homeOffice[index].purchase_oct : 0
    );
    const purchaseNov = parseFloat(
      homeOffice[index].purchase_nov ? homeOffice[index].purchase_nov : 0
    );
    const purchaseDec = parseFloat(
      homeOffice[index].purchase_dec ? homeOffice[index].purchase_dec : 0
    );

    const onlyViewYearly =
    
      purchaseJan > 0 ||
      purchaseFeb > 0 ||
      purchaseMar > 0 ||
      purchaseApr > 0 ||
      purchaseMay > 0 ||
      purchaseJun > 0 ||
      purchaseJul > 0 ||
      purchaseAug > 0 ||
      purchaseSep > 0 ||
      purchaseOct > 0 ||
      purchaseNov > 0 ||
      purchaseDec > 0;

    const purchaseYearly = onlyViewYearly ? 0 : homeOffice[index].purchase_yearly;

    const onlyViewMonthly = purchaseYearly > 0;


    const scope = {
    
     
      purchase_jan: purchaseJan,
      purchase_feb: purchaseFeb,
      purchase_mar: purchaseMar,
      purchase_apr: purchaseApr,
      purchase_may: purchaseMay,
      purchase_jun: purchaseJun,
      purchase_jul: purchaseJul,
      purchase_aug: purchaseAug,
      purchase_sep: purchaseSep,
      purchase_oct: purchaseOct,
      purchase_nov: purchaseNov,
      purchase_dec: purchaseDec,
      purchase_total: purchaseTotal,
      purchase_yearly: purchaseYearly      
    };  
    
    async function calculate() {
      const result =
        await scope3Service.calculateHomeWorkDisplacementHomeOffice(populateScopeParams(scope, inventorySelected.year));
      
      if (result) {
        homeOffice[index].only_view_yearly = onlyViewYearly;
        homeOffice[index].only_view_monthly = onlyViewMonthly;        
        homeOffice[index].emission_co2 = result.emission_co2;
        homeOffice[index].emission_ch4 = result.emission_ch4;
        homeOffice[index].emission_n2o = result.emission_n2o;
        homeOffice[index].purchase_total = result.purchase_total;
        homeOffice[index].emission_co2_jan = result.emission_co2_jan;
        homeOffice[index].emission_co2_feb = result.emission_co2_feb;
        homeOffice[index].emission_co2_mar = result.emission_co2_mar;
        homeOffice[index].emission_co2_apr = result.emission_co2_apr;
        homeOffice[index].emission_co2_may = result.emission_co2_may;
        homeOffice[index].emission_co2_jun = result.emission_co2_jun;
        homeOffice[index].emission_co2_jul = result.emission_co2_jul;
        homeOffice[index].emission_co2_aug = result.emission_co2_aug;
        homeOffice[index].emission_co2_sep = result.emission_co2_sep;
        homeOffice[index].emission_co2_oct = result.emission_co2_oct;
        homeOffice[index].emission_co2_nov = result.emission_co2_nov;
        homeOffice[index].emission_co2_dec = result.emission_co2_dec;
       

        setChangeHomeOffice(
          "change_scope_item_" +
            index +
            "_" +
            purchaseJan +
            "_" +
            purchaseFeb +
            "_" +
            purchaseMar +
            "_" +
            purchaseApr +
            "_" +
            purchaseMay +
            "_" +
            purchaseJun +
            "_" +
            purchaseJul +
            "_" +
            purchaseAug +
            "_" +
            purchaseSep +
            "_" +
            purchaseOct +
            "_" +
            purchaseNov +
            "_" +
            purchaseDec +
            "_" +
            purchaseYearly
        );

        setScopeValues(homeOffice, ignore_field);
        calculateTotals(homeOffice);
      }
    }

    calculate();
  };

  // Handle for table 2
  const handleChangeValueT2 = (index: number, ignore_field?: string) => {
    const utils_day_work_home  = parseFloat(
      homeOfficeT2[index].utils_day_work_home ? homeOfficeT2[index].utils_day_work_home  : 0
    );
    const average_consume  = parseFloat(
      homeOfficeT2[index].average_consume ? homeOfficeT2[index].average_consume  : 0
    );
    const total_days_year  = parseFloat(
      homeOfficeT2[index].total_days_year ? homeOfficeT2[index].total_days_year   : 0
    );
    const purchaseJan = parseFloat(
      homeOfficeT2[index].purchase_jan ? homeOfficeT2[index].purchase_jan : 0
    );
    const purchaseFeb = parseFloat(
      homeOfficeT2[index].purchase_feb ? homeOfficeT2[index].purchase_feb : 0
    );
    const purchaseMar = parseFloat(
      homeOfficeT2[index].purchase_mar ? homeOfficeT2[index].purchase_mar : 0
    );
    const purchaseApr = parseFloat(
      homeOfficeT2[index].purchase_apr ? homeOfficeT2[index].purchase_apr : 0
    );
    const purchaseMay = parseFloat(
      homeOfficeT2[index].purchase_may ? homeOfficeT2[index].purchase_may : 0
    );
    const purchaseJun = parseFloat(
      homeOfficeT2[index].purchase_jun ? homeOfficeT2[index].purchase_jun : 0
    );
    const purchaseJul = parseFloat(
      homeOfficeT2[index].purchase_jul ? homeOfficeT2[index].purchase_jul : 0
    );
    const purchaseAug = parseFloat(
      homeOfficeT2[index].purchase_aug ? homeOfficeT2[index].purchase_aug : 0
    );
    const purchaseSep = parseFloat(
      homeOfficeT2[index].purchase_sep ? homeOfficeT2[index].purchase_sep : 0
    );
    const purchaseOct = parseFloat(
      homeOfficeT2[index].purchase_oct ? homeOfficeT2[index].purchase_oct : 0
    );
    const purchaseNov = parseFloat(
      homeOfficeT2[index].purchase_nov ? homeOfficeT2[index].purchase_nov : 0
    );
    const purchaseDec = parseFloat(
      homeOfficeT2[index].purchase_dec ? homeOfficeT2[index].purchase_dec : 0
    );

    const onlyViewYearly =
    
      purchaseJanT2 > 0 ||
      purchaseFebT2 > 0 ||
      purchaseMarT2 > 0 ||
      purchaseAprT2 > 0 ||
      purchaseMayT2 > 0 ||
      purchaseJunT2 > 0 ||
      purchaseJulT2 > 0 ||
      purchaseAugT2 > 0 ||
      purchaseSepT2 > 0 ||
      purchaseOctT2 > 0 ||
      purchaseNovT2 > 0 ||
      purchaseDecT2 > 0;

    const purchaseYearly = onlyViewYearly ? 0 : homeOfficeT2[index].purchase_yearly;

    const onlyViewMonthly = purchaseYearly > 0;


    const scope = {
    
     
      purchase_jan: purchaseJanT2,
      purchase_feb: purchaseFebT2,
      purchase_mar: purchaseMarT2,
      purchase_apr: purchaseAprT2,
      purchase_may: purchaseMayT2,
      purchase_jun: purchaseJunT2,
      purchase_jul: purchaseJulT2,
      purchase_aug: purchaseAugT2,
      purchase_sep: purchaseSepT2,
      purchase_oct: purchaseOctT2,
      purchase_nov: purchaseNovT2,
      purchase_dec: purchaseDecT2,
      purchase_total: purchaseTotal,
      purchase_yearly: purchaseYearly      
    };  
    
    async function calculate() {
      const result =
        await scope3Service.calculateHomeWorkDisplacementHomeOffice(populateScopeParams(scope, inventorySelectedT2.year));
      
      if (result) {
        homeOfficeT2[index].only_view_yearly = onlyViewYearly;
        homeOfficeT2[index].only_view_monthly = onlyViewMonthly;        
        homeOfficeT2[index].emission_co2 = result.emission_co2;
        homeOfficeT2[index].emission_ch4 = result.emission_ch4;
        homeOfficeT2[index].emission_n2o = result.emission_n2o;
        homeOfficeT2[index].purchase_total = result.purchase_total;
        homeOfficeT2[index].emission_co2_jan = result.emission_co2_jan;
        homeOfficeT2[index].emission_co2_feb = result.emission_co2_feb;
        homeOfficeT2[index].emission_co2_mar = result.emission_co2_mar;
        homeOfficeT2[index].emission_co2_apr = result.emission_co2_apr;
        homeOfficeT2[index].emission_co2_may = result.emission_co2_may;
        homeOfficeT2[index].emission_co2_jun = result.emission_co2_jun;
        homeOfficeT2[index].emission_co2_jul = result.emission_co2_jul;
        homeOfficeT2[index].emission_co2_aug = result.emission_co2_aug;
        homeOfficeT2[index].emission_co2_sep = result.emission_co2_sep;
        homeOfficeT2[index].emission_co2_oct = result.emission_co2_oct;
        homeOfficeT2[index].emission_co2_nov = result.emission_co2_nov;
        homeOfficeT2[index].emission_co2_dec = result.emission_co2_dec;
       

        setChangeHomeOfficeT2(
          "change_scope_item_" +
            index +
            "_" +
            purchaseJanT2 +
            "_" +
            purchaseFebT2 +
            "_" +
            purchaseMarT2 +
            "_" +
            purchaseAprT2 +
            "_" +
            purchaseMayT2 +
            "_" +
            purchaseJunT2 +
            "_" +
            purchaseJulT2 +
            "_" +
            purchaseAugT2 +
            "_" +
            purchaseSepT2 +
            "_" +
            purchaseOctT2 +
            "_" +
            purchaseNovT2 +
            "_" +
            purchaseDecT2 +
            "_" +
            purchaseYearlyT2
        );

        setScopeValuesT2(homeOfficeT2, ignore_field);
        calculateTotalsT2(homeOfficeT2);
      }
    }

    calculate();
  };

  // Handle for table 2
  const handleChangeValueT3 = (index: number, ignore_field?: string) => {
    const number_employees  = parseFloat(
      homeOfficeT3[index].number_employees ? homeOfficeT3[index].number_employees  : 0
    );
    const days_worked_home_office  = parseFloat(
      homeOfficeT3[index].days_worked_home_office ? homeOfficeT3[index].days_worked_home_office  : 0
    );

    const average_consumption_calc = calculateAverageConsumptionEletricity(index);

    const average_consumption_employee = 150.0
    const days_worked_year = 230.0
    const average_consumption_electricity = average_consumption_calc

    const calc_emissionCo2 = average_consumption_electricity ? average_consumption_electricity * days_worked_year : 0
    

    const scope = {
      emission_co2: calc_emissionCo2,
      number_employees: number_employees,
      days_worked_home_office: days_worked_home_office,
      average_consumption_employee: average_consumption_employee,
      days_worked_year: days_worked_year,
      average_consumption_electricity: average_consumption_electricity,     
    };  
    
    homeOfficeT3[index].average_consumption_employee = scope.average_consumption_employee
    homeOfficeT3[index].days_worked_year = scope.days_worked_year
    homeOfficeT3[index].average_consumption_electricity = scope.average_consumption_electricity      
    homeOfficeT3[index].emission_co2 = scope.emission_co2      

    setScopeValuesT3(homeOfficeT3, ignore_field);
    setChangeHomeOfficeT3(
      "change_scope_item_" +
        index +
        "_" +
        number_employees +
        "_" +
        days_worked_home_office +
        "_" +
        average_consumption_employee +
        "_" +
        days_worked_year +
        "_" +
        calc_emissionCo2 +
        "_" +
        average_consumption_electricity
    );

    calculateTotalsT3();
  };

  const setScopeValues = (itens: any, ignore_field?: string) => {
    let changeScope = "change_scope_item_";

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i];

        setValue(`homeOffice.${i}.source`, item.source);
        setValue(`homeOffice.${i}.description`, item.description);

        if ("utils_day_work_home" !== ignore_field) {
          setValue(
            `homeOffice.${i}.utils_day_work_home`,
            formatPrice(item.utils_day_work_home, false)
          );
        }

        if ("average_consume" !== ignore_field) {
          setValue(
            `homeOffice.${i}.average_consume`,
            formatPrice(item.average_consume, false)
          );
        }

        if ("total_days_year" !== ignore_field) {
          setValue(
            `homeOffice.${i}.total_days_year`,
            formatPrice(item.total_days_year, false)
          );
        }

        if ("purchase_jan" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_jan`,
            formatPrice5(item.purchase_jan, false)
          );
        }

        if ("purchase_feb" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_feb`,
            formatPrice5(item.purchase_feb, false)
          );
        }

        if ("purchase_mar" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_mar`,
            formatPrice5(item.purchase_mar, false)
          );
        }

        if ("purchase_apr" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_apr`,
            formatPrice5(item.purchase_apr, false)
          );
        }

        if ("purchase_may" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_may`,
            formatPrice5(item.purchase_may, false)
          );
        }

        if ("purchase_jun" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_jun`,
            formatPrice5(item.purchase_jun, false)
          );
        }

        if ("purchase_jul" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_jul`,
            formatPrice5(item.purchase_jul, false)
          );
        }

        if ("purchase_aug" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_aug`,
            formatPrice5(item.purchase_aug, false)
          );
        }

        if ("purchase_sep" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_sep`,
            formatPrice5(item.purchase_sep, false)
          );
        }

        if ("purchase_oct" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_oct`,
            formatPrice5(item.purchase_oct, false)
          );
        }

        if ("purchase_nov" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_nov`,
            formatPrice5(item.purchase_nov, false)
          );
        }

        if ("purchase_dec" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_dec`,
            formatPrice5(item.purchase_dec, false)
          );
        }

        if ("purchase_yearly" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_yearly`,
            formatPrice5(item.purchase_yearly, false)
          );
        }

        if ("purchase_total" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_total`,
            formatPrice3(item.purchase_total, false)
          );
        }

        if ("emission_co2" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2`,
            formatPrice3(item.emission_co2, false)
          );
        }

        if ("emission_ch4" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_ch4`,
            formatPrice3(item.emission_ch4, false)
          );
        }

        if ("emission_n2o" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_n2o`,
            formatPrice3(item.emission_n2o, false)
          );
        }

        if ("emission_co2_jan" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_jan`,
            formatPrice3(item.emission_co2_jan, false)
          );
        }

        if ("emission_co2_feb" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_feb`,
            formatPrice3(item.emission_co2_feb, false)
          );
        }

        if ("emission_co2_mar" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_mar`,
            formatPrice3(item.emission_co2_mar, false)
          );
        }

        if ("emission_co2_apr" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_apr`,
            formatPrice3(item.emission_co2_apr, false)
          );
        }

        if ("emission_co2_may" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_may`,
            formatPrice3(item.emission_co2_may, false)
          );
        }

        if ("emission_co2_jun" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_jun`,
            formatPrice3(item.emission_co2_jun, false)
          );
        }

        if ("emission_co2_jul" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_jul`,
            formatPrice3(item.emission_co2_jul, false)
          );
        }

        if ("emission_co2_aug" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_aug`,
            formatPrice3(item.emission_co2_aug, false)
          );
        }

        if ("emission_co2_sep" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_sep`,
            formatPrice3(item.emission_co2_sep, false)
          );
        }

        if ("emission_co2_oct" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_oct`,
            formatPrice3(item.emission_co2_oct, false)
          );
        }

        if ("emission_co2_nov" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_nov`,
            formatPrice3(item.emission_co2_nov, false)
          );
        }

        if ("emission_co2_dec" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_dec`,
            formatPrice3(item.emission_co2_dec, false)
          );
        }

        changeScope =
          changeScope +
          item.car_fleet_id +
          "_" +
          item.year +
          "_" +
          item.purchase_jan +
          "_" +
          item.purchase_feb +
          "_" +
          item.purchase_mar +
          "_" +
          item.purchase_apr +
          "_" +
          item.purchase_may +
          "_" +
          item.purchase_jun +
          "_" +
          item.purchase_jul +
          "_" +
          item.purchase_aug +
          "_" +
          item.purchase_sep +
          "_" +
          item.purchase_oct +
          "_" +
          item.purchase_nov +
          "_" +
          item.purchase_dec +
          "_" +
          item.purchase_yearly;
      }
    }

    setHomeOffice(itens);
    setChangeHomeOffice(changeScope);
  };

  // Scope for table 2:
  const setScopeValuesT2 = (itens: any, ignore_field?: string) => {
    let changeScope = "change_scope_item_";

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i];

        setValue(`homeOfficeT2.${i}.source`, item.source);
        setValue(`homeOfficeT2.${i}.description`, item.description);

        if ("utils_day_work_home" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.utils_day_work_home`,
            formatPrice(item.utils_day_work_home, false)
          );
        }

        if ("average_consume" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.average_consume`,
            formatPrice(item.average_consume, false)
          );
        }

        if ("total_days_year" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.total_days_year`,
            formatPrice(item.total_days_year, false)
          );
        }

        if ("purchase_jan" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_jan`,
            formatPrice5(item.purchase_jan, false)
          );
        }

        if ("purchase_feb" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_feb`,
            formatPrice5(item.purchase_feb, false)
          );
        }

        if ("purchase_mar" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_mar`,
            formatPrice5(item.purchase_mar, false)
          );
        }

        if ("purchase_apr" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_apr`,
            formatPrice5(item.purchase_apr, false)
          );
        }

        if ("purchase_may" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_may`,
            formatPrice5(item.purchase_may, false)
          );
        }

        if ("purchase_jun" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_jun`,
            formatPrice5(item.purchase_jun, false)
          );
        }

        if ("purchase_jul" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_jul`,
            formatPrice5(item.purchase_jul, false)
          );
        }

        if ("purchase_aug" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_aug`,
            formatPrice5(item.purchase_aug, false)
          );
        }

        if ("purchase_sep" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_sep`,
            formatPrice5(item.purchase_sep, false)
          );
        }

        if ("purchase_oct" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_oct`,
            formatPrice5(item.purchase_oct, false)
          );
        }

        if ("purchase_nov" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_nov`,
            formatPrice5(item.purchase_nov, false)
          );
        }

        if ("purchase_dec" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_dec`,
            formatPrice5(item.purchase_dec, false)
          );
        }

        if ("purchase_yearly" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_yearly`,
            formatPrice5(item.purchase_yearly, false)
          );
        }

        if ("purchase_total" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.purchase_total`,
            formatPrice3(item.purchase_total, false)
          );
        }

        if ("emission_co2" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2`,
            formatPrice3(item.emission_co2, false)
          );
        }

        if ("emission_ch4" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_ch4`,
            formatPrice3(item.emission_ch4, false)
          );
        }

        if ("emission_n2o" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_n2o`,
            formatPrice3(item.emission_n2o, false)
          );
        }

        if ("emission_co2_jan" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_jan`,
            formatPrice3(item.emission_co2_jan, false)
          );
        }

        if ("emission_co2_feb" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_feb`,
            formatPrice3(item.emission_co2_feb, false)
          );
        }

        if ("emission_co2_mar" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_mar`,
            formatPrice3(item.emission_co2_mar, false)
          );
        }

        if ("emission_co2_apr" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_apr`,
            formatPrice3(item.emission_co2_apr, false)
          );
        }

        if ("emission_co2_may" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_may`,
            formatPrice3(item.emission_co2_may, false)
          );
        }

        if ("emission_co2_jun" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_jun`,
            formatPrice3(item.emission_co2_jun, false)
          );
        }

        if ("emission_co2_jul" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_jul`,
            formatPrice3(item.emission_co2_jul, false)
          );
        }

        if ("emission_co2_aug" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_aug`,
            formatPrice3(item.emission_co2_aug, false)
          );
        }

        if ("emission_co2_sep" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_sep`,
            formatPrice3(item.emission_co2_sep, false)
          );
        }

        if ("emission_co2_oct" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_oct`,
            formatPrice3(item.emission_co2_oct, false)
          );
        }

        if ("emission_co2_nov" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_nov`,
            formatPrice3(item.emission_co2_nov, false)
          );
        }

        if ("emission_co2_dec" !== ignore_field) {
          setValue(
            `homeOfficeT2.${i}.emission_co2_dec`,
            formatPrice3(item.emission_co2_dec, false)
          );
        }

        changeScope =
          changeScope +
          item.car_fleet_id +
          "_" +
          item.year +
          "_" +
          item.purchase_jan +
          "_" +
          item.purchase_feb +
          "_" +
          item.purchase_mar +
          "_" +
          item.purchase_apr +
          "_" +
          item.purchase_may +
          "_" +
          item.purchase_jun +
          "_" +
          item.purchase_jul +
          "_" +
          item.purchase_aug +
          "_" +
          item.purchase_sep +
          "_" +
          item.purchase_oct +
          "_" +
          item.purchase_nov +
          "_" +
          item.purchase_dec +
          "_" +
          item.purchase_yearly;
      }
    }

    setHomeOfficeT2(itens);
    setChangeHomeOfficeT2(changeScope);
  };

  // Scope for table 3:
  const setScopeValuesT3 = (itens: any, ignore_field?: string) => {
    let changeScope = "change_scope_item_";
    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i];
        setValue(`homeOfficeT3.${i}.source`, item.source);
        setValue(`homeOfficeT3.${i}.description`, item.description);

        if ("number_employees" !== ignore_field) {
          setValue(
            `homeOfficeT3.${i}.number_employees`,
            formatPrice(item.number_employees, false)
          );
        }

        if ("days_worked_home_office" !== ignore_field) {
          setValue(
            `homeOfficeT3.${i}.days_worked_home_office`,
            formatPrice(item.days_worked_home_office, false)
          );
        }

        if ("average_consumption_employee" !== ignore_field) {
          setValue(
            `homeOfficeT3.${i}.average_consumption_employee`, 
            formatPrice(item.average_consumption_employee, false)
          );
        }

        if ("average_consumption_electricity" !== ignore_field) {
          setValue(
            `homeOfficeT3.${i}.average_consumption_electricity`,
            formatPrice(item.average_consumption_electricity, false)
          );
        }

        if ("days_worked_year" !== ignore_field) {
          setValue(
            `homeOfficeT3.${i}.days_worked_year`,
            formatPrice(item.days_worked_year, false)
          );
        }

        if ("emission_co2" !== ignore_field) {
          setValue(
            `homeOfficeT3.${i}.emission_co2`,
            formatPrice3(item.emission_co2, false)
          );
        }

        setValue(`homeOfficeT3.${i}.average_consumption_employee`, 150);
        setValue(`homeOfficeT3.${i}.days_worked_year`, 230);


        changeScope =
          changeScope +
          item.source +
          "_" +
          item.description +
          "_" +
          item.number_employees +
          "_" +
          item.days_worked_home_office +
          "_" +
          item.average_consumption_employee +
          "_" +
          item.average_consumption_electricity +
          "_" +
          item.days_worked_year +
          "_" +
          item.emission_co2;
      }

    }

    setHomeOfficeT3(itens);
    setChangeHomeOfficeT3(changeScope);
    sumTotalAllTablesEmissionCo2()
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  const downloadFile = (filename: string) => {
    window.open(filename, "_blank");
  };

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id);

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          "deslocamento_casa_trabalho",
          "home_office"
        );

        setFiles(result.files);
      }
    }

    deleteFile(id);
  }
  
  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: "deslocamento_casa_trabalho",
        file: base64,
        filename: filename,
        second_name: "home_office",
      };
      
      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      );

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          "deslocamento_casa_trabalho",
          "home_office"
        );

        setFiles(result.files);
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64 = reader.result;

        saveImage(base64?.toString(), file.name);
      };
    }
  };

  const handleChangeHomeOffice = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await homeOfficeService.getById(id);

      if (result) {
        const item = {
          city: result.city,
          state: result.state,
          private_name: result.name,
          company_name: result.company_name,
          only_view_monthly: false,
          only_view_yearly: true,
          average_consume: result.consumption,
          purchase_jan: result.consumption,
          purchase_feb: result.consumption,
          purchase_mar: result.consumption,
          purchase_apr: result.consumption,
          purchase_may: result.consumption,
          purchase_jun: result.consumption,
          purchase_jul: result.consumption,
          purchase_aug: result.consumption,
          purchase_sep: result.consumption,
          purchase_oct: result.consumption,
          purchase_nov: result.consumption,
          purchase_dec: result.consumption,
        };

        const index = homeOffice.length;

        const itens = homeOffice.concat(item);

        setChangeHomeOffice("new_scope_item_" + index);

        setScopeValues(itens);
        calculateTotals(itens);
      }
    }

    if (e) {
      retrieveServiceById(e.value);
    }

    setValue("home_office_params", []);
  };

  // Handle for table 2:
  const handleChangeHomeOfficeT2 = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await homeOfficeService.getById(id);

      if (result) {
        const item = {
          city: result.city,
          state: result.state,
          private_name: result.name,
          company_name: result.company_name,
          only_view_monthly: false,
          only_view_yearly: false,
        };

        const index = homeOfficeT2.length;

        const itens = homeOfficeT2.concat(item);

        setChangeHomeOfficeT2("new_scope_item_" + index);

        setScopeValuesT2(itens);
        calculateTotalsT2(itens); 
      }
    }

    if (e) {
      retrieveServiceById(e.value);
    }

    setValue("home_office_params", []);
  };

  // Handle for table 3:
  const handleChangeHomeOfficeT3 = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await homeOfficeService.getById(id);

      if (result) {
        const item = {
          city: result.city,
          state: result.state,
          private_name: result.name,
          company_name: result.company_name,
          only_view_monthly: false,
          only_view_yearly: false,
        };

        const index = homeOfficeT3.length;

        const itens = homeOfficeT3.concat(item);

        setChangeHomeOfficeT3("new_scope_item_" + index);

        setScopeValuesT3(itens);

        // calculateTotals(itens);
        calculateTotalsT3() 
      }
    }

    if (e) {
      retrieveServiceById(e.value);
    }

    setValue("home_office_params", []);
  };

  const handleOpenModalImport = () => {
    setOpenModalImport(!openModalImport);
  };

  const handleOpenModalImportT2 = () => {
    setOpenModalImportT2(!openModalImportT2);
  };

  const handleOpenModalImportT3 = () => {
    setOpenModalImportT3(!openModalImportT3);
  };

  const handleImport = (type?: number, data?: any[]) => {
    async function importData() {
      if (data) {
        setLoadingModal(true);

        const errors: any[] = [];
        const itens = homeOffice;

        const monthsDefault: any = await utilsService.getDifferenceDaysMonth({
          year: inventorySelected.year,
        });

        for (let i = 1; i < data?.length; i++) {
          
          const city = data[i][12];
          const state = data[i][13];
          
          if (city || state) {
            let obj: any = undefined;
            
            for (const t of homeEnergy) {
              if (t.city === city && t.state === state) {
                obj = t;
                break;
              }
            }
            
            if (obj) {
              
              const homeObj = await homeOfficeService.getById(obj.value);
              

              if (homeObj) {
                const register = data[i][0];
                const admission = data[i][1];
                const resignation = data[i][2];
                const total_days_year = data[i][3];
                const removals = data [i][4];
                const vancances = data[i][5];
                const coletive_vacances = data[i][6];
                const absences = data[i][7];
                const yearly = data[i][8];
                const average_consume = data[i][9];
                const utils_day_work_home = data[i][10];                
                const workload = data[i][11];
                
                               
                
                let months: any = {};
                if (admission && resignation) {
                  months = await utilsService.getDifferenceDaysMonth({
                    start_date: admission,
                    end_date: resignation,
                    year: inventorySelected.year,
                  });
                } else if (admission) {
                  months = await utilsService.getDifferenceDaysMonth({
                    start_date: admission,
                    year: inventorySelected.year,
                  });
                } else if (resignation) {
                  months = await utilsService.getDifferenceDaysMonth({
                    end_date: resignation,
                    year: inventorySelected.year,
                  });
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  months = await utilsService.getDifferenceDaysMonth({
                    year: inventorySelected.year,
                  });
                }

                let work_default = 0;

                if (homeObj.devices) {
                  for (const i of homeObj.devices) {
                    if (i.hours > work_default) {
                      work_default = i.hours;
                    }
                  }
                } else {
                  work_default = 8;
                }
                const absencesDays = ((removals || 0) + (vancances || 0) + (coletive_vacances || 0) + (absences || 0)) /12

                const factor_by_day = workload / work_default;
                      console.log (factor_by_day)
                
                const meses = [
                  "jan", "feb", "mar", "apr", "may", "jun",
                  "jul", "aug", "sep", "oct", "nov", "dec"
                ];
                
                const purchaseValues: any = {};

               

                for (const month of meses) {
                    const daysInMonth = months[month] || 0; // Dias trabalhados no mês específico
                    const totalDaysInMonth = monthsDefault[month]; // Total de dias no mês
                    
                  if (daysInMonth === 0 && yearly) {
                    
                      // Calcula o valor baseado em yearly
                      purchaseValues[month] = parseFloat(
                          (
                              (yearly / 12)  // Distribui o valor anual pelos meses
                          ).toFixed(3)  
                      );
                  } else {

                                        
                      const workedDaysInHome = (daysInMonth - utils_day_work_home)
                      
                      const workedDaysInMonth = Math.max(workedDaysInHome - absencesDays, 0); 

                                       
                          purchaseValues[month] = parseFloat(
                          (
                              (workedDaysInMonth / totalDaysInMonth) * // Proporção de dias trabalhados válidos
                              homeObj.consumption *                   // Consumo total
                              factor_by_day /                          // Fator de ajuste pela carga horária
                              1000                                     // Conversão para a unidade desejada (por exemplo, kWh)
                          ).toFixed(3)                                 // Arredondamento para 3 casas decimais
                      );
                  }
              }
                
              

                const scope = {
                  city: city,
                  state: state,
                  source: String(register),                  
                  utils_day_work_home : utils_day_work_home,  
                  average_consume : average_consume,
                  total_days_year : total_days_year,                
                  description: "Pessoa " + register,
                 
                  purchase_jan: purchaseValues["jan"],
                  purchase_feb: purchaseValues["feb"],
                  purchase_mar: purchaseValues["mar"],
                  purchase_apr: purchaseValues["apr"],
                  purchase_may: purchaseValues["may"],
                  purchase_jun: purchaseValues["jun"],
                  purchase_jul: purchaseValues["jul"],
                  purchase_aug: purchaseValues["aug"],
                  purchase_sep: purchaseValues["sep"],
                  purchase_oct: purchaseValues["oct"],
                  purchase_nov: purchaseValues["nov"],
                  purchase_dec: purchaseValues["dec"],
                  emission_co2: 0,
                  emission_ch4: 0,
                  emission_n2o: 0,
                  purchase_total: 0,
                  purchase_yearly: 0,
                  emission_co2_jan: 0,
                  emission_co2_feb: 0,
                  emission_co2_mar: 0,
                  emission_co2_apr: 0,
                  emission_co2_may: 0,
                  emission_co2_jun: 0,
                  emission_co2_jul: 0,
                  emission_co2_aug: 0,
                  emission_co2_sep: 0,
                  emission_co2_oct: 0,
                  emission_co2_nov: 0,
                  emission_co2_dec: 0,
                  only_view_yearly: true,
                  only_view_monthly: false,
                  company_name: homeObj.name,
                };                 

                const result =
                  await scope3Service.calculateHomeWorkDisplacementHomeOffice(populateScopeParams(scope, inventorySelected.year));

                if (result) {
                  scope.utils_day_work_home = result.utils_day_work_home || utils_day_work_home;
                  scope.average_consume = result.average_consume || average_consume;
                  scope.total_days_year = result.total_days_year  || total_days_year;
                  scope.emission_co2 = result.emission_co2;
                  scope.emission_ch4 = result.emission_ch4;
                  scope.emission_n2o = result.emission_n2o;
                  scope.purchase_total = result.purchase_total;
                  scope.emission_co2_jan = result.emission_co2_jan;
                  scope.emission_co2_feb = result.emission_co2_feb;
                  scope.emission_co2_mar = result.emission_co2_mar;
                  scope.emission_co2_apr = result.emission_co2_apr;
                  scope.emission_co2_may = result.emission_co2_may;
                  scope.emission_co2_jun = result.emission_co2_jun;
                  scope.emission_co2_jul = result.emission_co2_jul;
                  scope.emission_co2_aug = result.emission_co2_aug;
                  scope.emission_co2_sep = result.emission_co2_sep;
                  scope.emission_co2_oct = result.emission_co2_oct;
                  scope.emission_co2_nov = result.emission_co2_nov;
                  scope.emission_co2_dec = result.emission_co2_dec;

                  itens.push(scope);
                }
              } else {
                errors.push({ id: i, city: city, state: state });
              }
            } else {
              errors.push({ id: i, city: city, state: state });
            }
          }
        }

        setChangeHomeOffice(`import_scope_data_${data?.length}`);

        setScopeValues(itens);
        calculateTotals(itens);

        if (errors && errors.length > 0) {
          handleOpenModalError(errors);
        }
      }

      setLoadingModal(false);
    }

    if (data) {
      importData();
    }
  };

  const handleImportDataBySpreadSheet = (data: any, model: string) => {
    const item: any = location?.state;

    console.log(item)

    const importSpreadsheet = async () => {
      if(data){
        setLoadingModal(true);
        const formData = new FormData();
        formData.append("file", data);
        let itens_imported: number = 0;
        let data_not_imported: number = 0;

        try {
          const response = await scope3Service.importDataBySpreadsheet(item.company_id, item.year, formData, model).then(response => {
                              itens_imported = response && response[0]?.data ? response[0].data.length : 0;
                              data_not_imported = response && response[0]?.data_not_imported ? response[0].data_not_imported.length : 0;

                              if (response?.error) {
                                toast.error(`${response?.error}`);
                              };

                              if(data_not_imported > 0){
                                toast.success(`Operação realizada com sucesso! Foram importados: ${itens_imported} itens e ${data_not_imported} NÃO foram importados.`);
                              } else if(!response?.error && itens_imported) {
                                toast.success(`Operação realizada com sucesso! Foram importados: ${itens_imported} itens.`);
                              } else {
                                toast.success(`Operação realizada com sucesso! Foram importados: ${itens_imported} itens.`);
                              };
                            }).finally(() => {
                              setLoadingModal(false);
                              handleOpenModalImportT3();
                              history.goBack();
                            });
        } catch (error) {
          console.error(error);
          setLoadingModal(false);
          setErrorApi("Ocorreu um erro ao importar a planilha.");
          toast.error(`Ocoreu um erro ao realizar a operação!`);
        }
      };
    };
    if(data) {
      importSpreadsheet()
    };
  };

  const handleOpenModalError = (data?: any) => {
    setImportErrors(data);
    setOpenModalError(!openModalError);
  };

  const populateScopeParams = (scope: any, year: number): EletricityParams => {
    return {

      purchase_jan: scope.purchase_jan,
      purchase_feb: scope.purchase_feb,
      purchase_mar: scope.purchase_mar,
      purchase_apr: scope.purchase_apr,
      purchase_may: scope.purchase_may,
      purchase_jun: scope.purchase_jun,
      purchase_jul: scope.purchase_jul,
      purchase_aug: scope.purchase_aug,
      purchase_sep: scope.purchase_sep,
      purchase_oct: scope.purchase_oct,
      purchase_nov: scope.purchase_nov,
      purchase_dec: scope.purchase_dec,
      emission_co2: scope.emission_co2,
      emission_ch4: scope.emission_ch4,
      emission_n2o: scope.emission_n2o,
      purchase_total: scope.purchase_total,
      purchase_yearly: scope.purchase_yearly,
      year: year  
    };
  };

  const sumTotalAllTablesEmissionCo2 = (scopes?: any) => {
    // Function reduce was created because in your first time into the page, the emission total co2 doesn't working, so needed to add this method to made sure working the calc.
    if(scopes){
      const sum_home_office_manual = scopes?.home_office_manual.reduce((accumulator: number, currentItem: any) => {
        if (currentItem && currentItem.emission_co2) {
          return accumulator + currentItem.emission_co2;
        }
        return accumulator;
      }, 0);
      const sum_home_office_automatico = scopes?.home_office_automatico.reduce((accumulator: number, currentItem: any) => {
        if (currentItem && currentItem.emission_co2) {
          return accumulator + currentItem.emission_co2;
        }
        return accumulator;
      }, 0);
      const sum_home_office_ghg = scopes?.home_office_ghg.reduce((accumulator: number, currentItem: any) => {
        if (currentItem && currentItem.emission_co2) {
          return accumulator + currentItem.emission_co2;
        }
        return accumulator;
      }, 0);

      const current_sum = sum_home_office_manual + sum_home_office_automatico + sum_home_office_ghg
      setTotalAllTablesEmissionCo2(current_sum);
      return current_sum;

    } else {
      const sum = emissionCo2 + emissionCo2T2 + emissionCo2T3
      setTotalAllTablesEmissionCo2(sum);
      return sum;
    }
  };

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 25,
            justifyContent: "space-between",
            marginBottom: 20,
          }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TitleText level={1} title={"Inventário: " + inventorySelected?.year + " - " + inventorySelected?.company?.corporate_name }/>
                <TitleText level={4} title={"Escopo 03 - " + title} />
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo} 
                style={{ margin: "auto 10px auto auto" }}>
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: "auto" }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        <div style={{width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",}}/>

        {ignoreFill && (
          <div style={{ display: "flex", flexDirection: "column", margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#31363F",
                opacity: "0.8",
                fontWeight: "bold",
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do
              escopo selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: "95%", height: 100 }}
              {...register("reason", { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        {!ignoreFill && (
          <div
            style={{
              width: "100%",
              borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",
            }}
          />
        )}

        {/* Beggin of Options */}
        {!ignoreFill && (
          <div
            style={{
              overflow: "hidden auto",
              position: "relative",
              marginTop: 0,
              height: "calc(100vh - 200px)",
              maxHeight: "calc(100vh - 200px)",
            }}
            className="custom-scrollbar"
          >
            {/* Beggin Table Option 1 */}
            <HomeOfficeSubHeader 
              descripton="Opção 1 - Quantidade total de eletricidade consumida em Home Offices preenchimento automático" 
              options_description="Selecione, a localidade ao lado, para adicionar nova linha"
              isCommonUser={isCommonUser}
              homeEnergy={homeEnergy}
              getValues={getValues}
              handleChangeHomeOffice={handleChangeHomeOffice}
              handleOpenModalImport={handleOpenModalImport}
              control={control}
            />

            <TableContainer isMenuOpen={isMenuOpen}
              className={`custom-scrollbar`}
            >
              {changeHomeOffice && homeOffice && homeOffice.length > 0 && (


                <HomeOfficeTable
                  typeHomeOffice="homeOffice"
                  fieldsMonthreadOnly={true}
                  homeOffice={homeOffice}
                  handleOpenModalDelete={handleOpenModalDelete}
                  handleDeleteAllScope={handleDeleteAllScope}
                  isCommonUser={isCommonUser}
                  register={register}
                  setValue={setValue}
                  formatInputNumber={formatInputNumber}
                  handleChangeValue={handleChangeValue}
                  formatPrice3={formatPrice3}
                  handleDeleteHomeOffice={handleDeleteHomeOffice}
                  purchaseJan={purchaseJan}
                  purchaseFeb={purchaseFeb}
                  purchaseMar={purchaseMar}
                  purchaseApr={purchaseApr}
                  purchaseMay={purchaseMay}
                  purchaseJun={purchaseJun}
                  purchaseJul={purchaseJul}
                  purchaseAug={purchaseAug}
                  purchaseSep={purchaseSep}
                  purchaseOct={purchaseOct}
                  purchaseNov={purchaseNov}
                  purchaseDec={purchaseDec}
                  purchaseYearly={purchaseYearly}
                  purchaseTotal={purchaseTotal}
                  emissionCo2={emissionCo2}
                  emissionCh4={emissionCh4}
                  emissionN2o={emissionN2o}
                />

              )}

              {!homeOffice.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum registro foi informado`
                      : `Nenhum registro foi informado, favor selecionar para
                    dar prosseguimento`
                  }
                />
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                }}
              >
                {errors && errors.homeOffice && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      margin: 10,
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </TableContainer>
            {/* End Table Option 1 */}
              
            {/* Beggin Table Option 2 */}
            <HomeOfficeSubHeader 
              descripton="Opção 1.1 - Quantidade total de eletricidade consumida em Home Offices preenchimento manual" 
              options_description="Selecione, a localidade ao lado, para adicionar nova linha"
              isCommonUser={isCommonUser}
              homeEnergy={homeEnergyT2}
              getValues={getValues}
              handleChangeHomeOffice={handleChangeHomeOfficeT2}
              handleOpenModalImport={handleOpenModalImportT2}
              control={control}
            />

            <TableContainer isMenuOpen={isMenuOpen}
              className="custom-scrollbar"
            >
              {changeHomeOfficeT2 && homeOfficeT2 && homeOfficeT2.length > 0 && (


                <HomeOfficeTable
                  typeHomeOffice="homeOfficeT2"
                  fieldsMonthreadOnly={false}
                  homeOffice={homeOfficeT2}
                  handleOpenModalDelete={handleOpenModalDelete}
                  handleDeleteAllScope={handleDeleteAllScopeT2}
                  isCommonUser={isCommonUser}
                  register={register}
                  setValue={setValue}
                  formatInputNumber={formatInputNumber}
                  handleChangeValue={handleChangeValueT2}
                  formatPrice3={formatPrice3}
                  handleDeleteHomeOffice={handleDeleteHomeOfficeT2}
                  purchaseJan={purchaseJanT2}
                  purchaseFeb={purchaseFebT2}
                  purchaseMar={purchaseMarT2}
                  purchaseApr={purchaseAprT2}
                  purchaseMay={purchaseMayT2}
                  purchaseJun={purchaseJunT2}
                  purchaseJul={purchaseJulT2}
                  purchaseAug={purchaseAugT2}
                  purchaseSep={purchaseSepT2}
                  purchaseOct={purchaseOctT2}
                  purchaseNov={purchaseNovT2}
                  purchaseDec={purchaseDecT2}
                  purchaseYearly={purchaseYearlyT2}
                  purchaseTotal={purchaseTotalT2}
                  emissionCo2={emissionCo2T2}
                  emissionCh4={emissionCh4T2}
                  emissionN2o={emissionN2oT2}
                />

              )}

              {!homeOfficeT2.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum registro foi informado`
                      : `Nenhum registro foi informado, favor selecionar para
                    dar prosseguimento`
                  }
                />
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                }}
              >
                {errors && errors.homeOffice && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      margin: 10,
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </TableContainer>

            {/* End Table Option 2 */}

            {/* Beggin Table Option 3 */}
            
            <HomeOfficeSubHeader 
              descripton="Opção 3 - Quantidade total de eletricidade consumida em Home Offices GHG" 
              options_description="Selecione, a localidade ao lado, para adicionar nova linha"
              isCommonUser={isCommonUser}
              homeEnergy={homeEnergyT3}
              getValues={getValues}
              handleChangeHomeOffice={handleChangeHomeOfficeT3}
              handleOpenModalImport={handleOpenModalImportT3}
              control={control}
            />

            <TableContainer isMenuOpen={isMenuOpen}
              className="custom-scrollbar"
            >

              {changeHomeOfficeT3 && homeOfficeT3 && homeOfficeT3.length > 0 && (
                <TableGHGHomeOffice 
                  isCommonUser={isCommonUser}
                  register={register}
                  setValue={setValue}
                  homeOfficeT3={homeOfficeT3}
                  handleChangeValue={handleChangeValueT3}
                  handleDeleteHomeOfficeT3={handleDeleteHomeOfficeT3}
                  handleDeleteAllScopeT3={handleDeleteAllScopeT3}
                  handleOpenModalDelete={handleOpenModalDelete}
                  emissionCo2T3={emissionCo2T3}
                  setLoadingModal={setLoadingModal}
                />
              )}


              {!homeOfficeT3.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum registro foi informado`
                      : `Nenhum registro foi informado, favor selecionar para
                    dar prosseguimento`
                  }
                />
              )}
            </TableContainer>

            {/* End Table Option 3 */}

            {/* Footer Resultado Tabela */}

            {!ignoreFill && changeHomeOffice && changeHomeOfficeT2 && changeHomeOfficeT3 && (
              <FooterResultTable
                result={formatPrice3(totalAllTablesEmissionCo2, false)}
                description="Emissões totais em CO₂ equivalente (toneladas métricas):"
              />
            )}


            {!ignoreFill && (
              <div
                style={{ display: "flex", flexDirection: "column", margin: 10 }}
              >
                <div
                  style={{
                    width: "100%",
                    borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 5,
                    marginRight: 30,
                  }}
                >
                  <span
                    style={{
                      textAlign: "start",
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Evidências do escopo informado
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 10,
                  }}
                >
                  <label
                    style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}
                  >
                    Observações sobre o escopo informado.
                  </label>

                  <textarea
                    readOnly={isCommonUser}
                    style={{ width: "100%", height: 100 }}
                    {...register("evidence")}
                  />

                  {errors.evidence && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}

                  <ScopeEvidenceAttachments
                    files={files}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={downloadFile}
                    onChangeAttachment={handleAttachmentChange}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {/* End of Options */}      
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && <span style={{ fontSize: 12 }}>{Parser(guidelines)}</span>}
        </div>
      </Modal>

      {openModalImport && (
        <ModalHandleImportData
          isOpen={openModalImport}
          handleModalVisible={handleOpenModalImport}
          title="Quantidade de eletricidade consumida em Home Office modelo automatico"
          templateFileName="template_displacement_home_office.xlsx"
          handleImportData={handleImportDataBySpreadSheet}
          option="model_automatico"
        />
      )}

      {openModalImportT2 && (
        <ModalHandleImportData
          isOpen={openModalImportT2}
          handleModalVisible={handleOpenModalImportT2}
          title="Quantidade de eletricidade consumida em Home Office modelo manual"
          templateFileName="template_displacement_Home.xlsx"
          handleImportData={handleImportDataBySpreadSheet}
          option="model_manual"
        />
      )}
      
      {openModalImportT3 && (
        <ModalHandleImportData
          isOpen={openModalImportT3}
          handleModalVisible={handleOpenModalImportT3}
          title="Quantidade de eletricidade consumida em Home Office modelo GHG"
          templateFileName="template_displacement_Home.xlsx"
          handleImportData={handleImportDataBySpreadSheet}
          option="model_ghg"
        />
      )}

      {openModalError && importErrors && (
        <Modal
          isOpen={openModalError}
          isPayOrTypeClient={true}
          handleModalVisible={handleOpenModalError}
        >
          <div style={{ margin: 5, textAlign: "left" }}>
            <TitleText
              level={3}
              title="Falha na importação de dados"
              color="red"
            />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
              Ocorreram falhas na importação dos dados previstos. Abaixo maiores
              detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }}>
            <table
              style={{ width: "100%", borderCollapse: "collapse" }}
              className="table-scope"
            >
              <thead>
                <tr
                  style={{
                    borderColor: "white",
                    borderWidth: 1,
                    borderStyle: "solid",
                  }}
                >
                  <th
                    style={{
                      backgroundColor: "#D8D7D7",
                      fontSize: 12,
                      height: 25,
                    }}
                    colSpan={3}
                  >
                    {"Falhas de Importação"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr
                        style={{
                          borderColor: "white",
                          borderWidth: 1,
                          borderStyle: "solid",
                        }}
                      >
                        <td
                          style={{
                            background: "#F6F6F6",
                            borderColor: "white",
                            borderWidth: 1,
                            borderStyle: "solid",
                          }}
                        >
                          <span
                            style={{
                              marginLeft: 5,
                              width: "20%",
                              fontSize: 12,
                              textAlign: "left",
                            }}
                          >
                            {"Linha " + item.id}
                          </span>
                        </td>
                        <td
                          style={{
                            background: "#F6F6F6",
                            borderColor: "white",
                            borderWidth: 1,
                            borderStyle: "solid",
                          }}
                        >
                          <span
                            style={{
                              marginLeft: 5,
                              width: "80%",
                              fontSize: 12,
                              textAlign: "left",
                            }}
                          >
                            {"Lançamento não importado com sucesso: " +
                              item.city +
                              " - " +
                              item.state}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal>
      )}

      {loadingModal && <Loading isOpen={loadingModal} />}

      {openModalDelete && (
      <ModalDeleteDataTable
        isOpen={openModalDelete}
        handleModalVisible={() => setOpenModalDelete(false)}
        handleOk={handleDeleteAllScope}
      />
    )}
    </Container>
  );
};
