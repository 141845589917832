import { Redirect, Switch } from 'react-router-dom'

import { Login } from '../pages/Login'
import { Dashboard } from '../pages/Dashboard'
import { TermsOfUse } from '../pages/TermsOfUse'
import { ForgotPassword } from '../pages/ForgotPassword'
import Route from './Route'
import { CreateAccount } from '../pages/CreateAccount'
import { User } from '../pages/User'
import { NewPassword } from '../pages/NewPassword'
import { Activity } from '../pages/Activity'
import { ActivityEdit } from '../pages/Activity/maintenance'
import { OrganismEdit } from '../pages/Organism/maintenance'
import { Organism } from '../pages/Organism'
import { EnergyTypeEdit } from '../pages/EnergyType/maintenance'
import { EnergyType } from '../pages/EnergyType'
import { UserInclude } from '../pages/User/maintenace_include'
import { UserEdit } from '../pages/User/maintenace_edit'
import { CompanyEdit } from '../pages/Company/maintenance_edit'
import { Company } from '../pages/Company'
import { CompanyRegister } from '../pages/Company/maintenance_include'
import { CompanyInfo } from '../pages/Company/maintenance_info'
import { CompanyScope } from '../pages/Company/maintenance_scope'
import { InventoryMethod } from '../pages/Inventory/maintenance/method'
import { Inventory } from '../pages/Inventory'
import { InventoryCompensation } from '../pages/Inventory/maintenance/compensation'
import { InventoryOther } from '../pages/Inventory/maintenance/other'
import { InventoryGoal } from '../pages/Inventory/maintenance/goal'
import { InventoryThirdPart } from '../pages/Inventory/maintenance/third_part'
import { InventoryOperationLimit } from '../pages/Inventory/maintenance/operational_limit'
import { InventoryInventoryLimit } from '../pages/Inventory/maintenance/inventory_limit'
import { InventoryBasicData } from '../pages/Inventory/maintenance/basicdata'
import { UnityEdit } from '../pages/Unity/maintenance'
import { Unity } from '../pages/Unity'
import { InventoryScope } from '../pages/Inventory/scope'
import { StationaryCombustion } from '../pages/Inventory/scope1/StationaryCombustion'
import { MobileCombustion } from '../pages/Inventory/scope1/MobileCombustion'
import { FugitiveEmissions } from '../pages/Inventory/scope1/FugitiveEmissions'
import { IndustrialProcesses } from '../pages/Inventory/scope1/IndustrialProcesses'
import { AgriculturalActivities } from '../pages/Inventory/scope1/AgriculturalActivities'
import { ChangesInLandUse } from '../pages/Inventory/scope1/ChangesInLandUse'
import { SolidWaste } from '../pages/Inventory/scope1/SolidWaste'
import { Incineration } from '../pages/Inventory/scope1/SolidWaste/Incineration'
import { EmissionsReport } from '../pages/Inventory/scope1/SolidWaste/EmissionsReport'
import { LandfilledWaste } from '../pages/Inventory/scope1/SolidWaste/LandfilledWaste'
import { AnaerobicCompost } from '../pages/Inventory/scope1/SolidWaste/AnaerobicCompost'
import { LiquidsEffluents } from '../pages/Inventory/scope1/LiquidsEffluents'
import { ElectricityLocation } from '../pages/Inventory/scope2/ElectricityLocation'
import { TedLossesLocation } from '../pages/Inventory/scope2/TedLossesLocation'
import { PurchaseThermalEnergy } from '../pages/Inventory/scope2/PurchaseThermalEnergy'
import { ElectricityPurchaseChoice } from '../pages/Inventory/scope2/ElectricityPurchaseChoice'
import { TedLossesPurchaseChoice } from '../pages/Inventory/scope2/TedLossesPurchaseChoice'
import { ScopeCategories3 } from '../pages/Inventory/scope3/ScopeCategories3'
import { TransportDistrUpstream } from '../pages/Inventory/scope3/TransportDistrUpstream'
import { SolidWasteOperation } from '../pages/Inventory/scope3/SolidWasteOperation'
import { EffluentsGeneratedOperation } from '../pages/Inventory/scope3/EffluentsGenerated/EffluentsGeneratedOperation'
import { BusinessTrips } from '../pages/Inventory/scope3/BusinessTrips'
import { HomeWorkDisplacement } from '../pages/Inventory/scope3/HomeWorkDisplacement'
import { TransportDistrDownstream } from '../pages/Inventory/scope3/TransportDistrDownstream'
import { Fuel } from '../pages/Fuel'
import { FuelEdit } from '../pages/Fuel/maintenance'
import { FuelAdd } from '../pages/Fuel/newFuel'
import { Sector } from '../pages/Sector'
import { SectorEdit } from '../pages/Sector/maintenance'
import { FuelFatorsCh4 } from '../pages/Fuel/factors_ch4'
import { FuelFatorsN2o } from '../pages/Fuel/factors_n2o'
import { GasEdit } from '../pages/Gas/maintenance'
import { Gas } from '../pages/Gas'
import { EquipamentEdit } from '../pages/Equipament/maintenance'
import { Equipament } from '../pages/Equipament'
import { TreatmentType } from '../pages/TreatmentType'
import { TreatmentTypeEdit } from '../pages/TreatmentType/maintenance'
import { Mail } from '../pages/Mail'
import { Audit } from '../pages/Audit'
import { CarFleetEdit } from '../pages/CarFleet/maintenance'
import { CarFleet } from '../pages/CarFleet'
import { CarFleetYear } from '../pages/CarFleet/factors_year'
import { FuelMonthly } from '../pages/Fuel/factors_month'
import { ClimaticCharacteristicEdit } from '../pages/ClimaticCharacteristic/maintenance'
import { ClimaticCharacteristic } from '../pages/ClimaticCharacteristic'
import { SeasonalData } from '../pages/SeasonalData'
import { SeasonalDataEdit } from '../pages/SeasonalData/maintenance'
import { HomeOfficeEdit } from '../pages/HomeOffice/maintenance'
import { HomeOffice } from '../pages/HomeOffice'
import { TransportTypeEdit } from '../pages/TransportType/maintenance'
import { TransportType } from '../pages/TransportType'
import { PublicTransport } from '../pages/Inventory/scope3/HomeWorkDisplacement/PublicTransport'
import { PrivateVehicles } from '../pages/Inventory/scope3/HomeWorkDisplacement/PrivateVehicles'
import { VehicleFuelEdit } from '../pages/VehicleFuel/maintenance'
import { VehicleFuel } from '../pages/VehicleFuel'
import { AnaerobicCompost3 } from '../pages/Inventory/scope3/SolidWasteOperation/AnaerobicCompost3'
import { LandfilledWaste3 } from '../pages/Inventory/scope3/SolidWasteOperation/LandfilledWaste3'
import { Incineration3 } from '../pages/Inventory/scope3/SolidWasteOperation/Incineration3'
import { Resume } from '../pages/Resume'
import { RpeResume } from '../pages/RpeResume'
import { MaccCurve } from '../pages/MaccCurve'
import { Project } from '../pages/Project'
import { ProjectEdit } from '../pages/Project/maintenance'
import { ScopeEdit } from '../pages/Scope/maintenance'
import { Scope } from '../pages/Scope'
import { MobileRoadTransport } from '../pages/Inventory/scope1/MobileCombustion/MobileRoadTransport'
import { MobileWaterTransport } from '../pages/Inventory/scope1/MobileCombustion/MobileWaterTransport'
import { MobileAirTransport } from '../pages/Inventory/scope1/MobileCombustion/MobileAirTransport'
import { RefrigerationEquipment } from '../pages/Inventory/scope1/FugitiveEmissions/RefrigerationEquipment'
import { HexafluorideEmissions } from '../pages/Inventory/scope1/FugitiveEmissions/HexafluorideEmissions'
import { FugitiveEmissionsReport } from '../pages/Inventory/scope1/FugitiveEmissions/FugitiveEmissionsReport'
import { MobileReport } from '../pages/Inventory/scope1/MobileCombustion/MobileReport'
import { NonKiotoFugitiveEmissions } from '../pages/Inventory/scope1/NonKiotoFugitiveEmissions'
import { Coprocessing } from '../pages/Inventory/scope1/SolidWaste/Coprocessing'
import { TddRoadTransport } from '../pages/Inventory/scope3/TransportDistrDownstream/TddRoadTransport'
import { TddWaterTransport } from '../pages/Inventory/scope3/TransportDistrDownstream/TddWaterTransport'
import { TddAirTransport } from '../pages/Inventory/scope3/TransportDistrDownstream/TddAirTransport'
import { TduAirTransport } from '../pages/Inventory/scope3/TransportDistrUpstream/TduAirTransport'
import { TduWaterTransport } from '../pages/Inventory/scope3/TransportDistrUpstream/TduWaterTransport'
import { TduRoadTransport } from '../pages/Inventory/scope3/TransportDistrUpstream/TduRoadTransport'
import { AirTrip } from '../pages/Inventory/scope3/BusinessTrips/AirTrip'
import { Airport } from '../pages/Airport'
import { AirportEdit } from '../pages/Airport/maintenance'
import { TrainTrip } from '../pages/Inventory/scope3/BusinessTrips/TrainTrip'
import { BusTrip } from '../pages/Inventory/scope3/BusinessTrips/BusTrip'
import { CarTrip } from '../pages/Inventory/scope3/BusinessTrips/CarTrip'
import { HelpTopic } from '../pages/HelpTopic'
import { HelpTopicEdit } from '../pages/HelpTopic/maintenance'
import { Help } from '../pages/Help'
import { TicketType } from '../pages/TicketType'
import { TicketTypeEdit } from '../pages/TicketType/maintenance'
import { StationaryFountains } from '../pages/Inventory/scope1/StationaryCombustion/StationaryFountains'
import { StationaryOthers } from '../pages/Inventory/scope1/StationaryCombustion/StationaryOthers'
import { EffluentsOthers } from '../pages/Inventory/scope1/LiquidsEffluents/EffluentsOthers'
import { Effluents } from '../pages/Inventory/scope1/LiquidsEffluents/Effluents'
import { HomeWorkHomeOffice } from '../pages/Inventory/scope3/HomeWorkDisplacement/HomeWorkHomeOffice'
import { Coprocessing3 } from '../pages/Inventory/scope3/SolidWasteOperation/Coprocessing3'
import { TrainCompaniesEdit } from '../pages/TrainCompanies/maintenance'
import { TrainCompanies } from '../pages/TrainCompanies'
import Indicators from '../pages/Indicators'
// import { TelaInicial } from '../pages/SplashScreen'
import { SoonPage } from '../pages/SoonPage'
import { IPCCEffluents } from '../pages/IPCCEffluents'
import { IPCCEffluentsEdit } from '../pages/IPCCEffluents/maintenance'
import { IPCCEffluents2006 } from '../pages/IPCCEffluents2006'
import { IPCCEffluents2006Edit } from '../pages/IPCCEffluents2006/maintenance'
import { EffluentsGenerated } from '../pages/Inventory/scope3/EffluentsGenerated'
import { EffluentsDomestic } from '../pages/Inventory/scope3/EffluentsGenerated/EffluentsDomestic'
import { EmissionsTotalsEffluents } from '../pages/Inventory/scope3/EffluentsGenerated/EffluentsTotals'

export function Routes() {
  return (
    <Switch>
      <Redirect from="/" to="/inventario" exact={true} />
      <Route path="/em-breve" exact component={SoonPage} />
      <Route path="/login" exact component={Login} />
      <Route path="/recuperar-senha" exact component={ForgotPassword} />
      <Route path="/criar-conta/:token" exact component={CreateAccount} />
      <Route path="/nova-senha" exact component={NewPassword} />
      {/* <Route path="/dashboard" exact component={Indicators} /> */}
      {/* <Route path="/inicio" exact component={TelaInicial} isPrivate /> */}
      <Route path="/inventario/dados-basicos" exact component={InventoryBasicData} isPrivate />
      <Route path="/inventario/auditoria" exact component={InventoryThirdPart} isPrivate />
      <Route path="/inventario/meta" exact component={InventoryGoal} isPrivate />
      <Route path="/inventario/limite-inventario" exact component={InventoryInventoryLimit} isPrivate />
      <Route path="/inventario/limite-operational" exact component={InventoryOperationLimit} isPrivate />
      <Route path="/inventario/metodo" exact component={InventoryMethod} isPrivate />
      <Route path="/inventario/compensacao" exact component={InventoryCompensation} isPrivate />
      <Route path="/inventario/outro" exact component={InventoryOther} isPrivate />

      <Route path="/inventario/escopo-1/atividades-agricolas" exact component={AgriculturalActivities} isPrivate />

      <Route path="/inventario/escopo-1/combustao-estacionaria/fontes-estacionarias" exact component={StationaryFountains} isPrivate />
      <Route path="/inventario/escopo-1/combustao-estacionaria/relato-de-emissoes" exact component={StationaryOthers} isPrivate />
      <Route path="/inventario/escopo-1/combustao-estacionaria" exact component={StationaryCombustion} isPrivate />

      <Route path="/inventario/escopo-1/combustao-movel/transporte-hidroviario" exact component={MobileWaterTransport} isPrivate />
      <Route path="/inventario/escopo-1/combustao-movel/transporte-rodoviario" exact component={MobileRoadTransport} isPrivate />
      <Route path="/inventario/escopo-1/combustao-movel/transporte-aereo" exact component={MobileAirTransport} isPrivate />
      <Route path="/inventario/escopo-1/combustao-movel/outras-emissoes" exact component={MobileReport} isPrivate />
      <Route path="/inventario/escopo-1/combustao-movel" exact component={MobileCombustion} isPrivate />

      <Route path="/inventario/escopo-1/efluentes-liquidos/relato-de-emissoes" exact component={EffluentsOthers} isPrivate />
      <Route path="/inventario/escopo-1/efluentes-liquidos/efluentes" exact component={Effluents} isPrivate />
      <Route path="/inventario/escopo-1/efluentes-liquidos" exact component={LiquidsEffluents} isPrivate />

      <Route path="/inventario/escopo-1/emissoes-fugitivas/equipamento-refrigeracao" exact component={RefrigerationEquipment} isPrivate />
      <Route path="/inventario/escopo-1/emissoes-fugitivas/emissoes-hexafluoreto" exact component={HexafluorideEmissions} isPrivate />
      <Route path="/inventario/escopo-1/emissoes-fugitivas/relato-de-emissoes" exact component={FugitiveEmissionsReport} isPrivate />
      <Route path="/inventario/escopo-1/emissoes-fugitivas" exact component={FugitiveEmissions} isPrivate />

      <Route path="/inventario/escopo-1/gee-nao-quioto" exact component={NonKiotoFugitiveEmissions} isPrivate />

      <Route path="/inventario/escopo-1/mudancas-no-uso-do-solo" exact component={ChangesInLandUse} isPrivate />

      <Route path="/inventario/escopo-1/processos-industriais" exact component={IndustrialProcesses} isPrivate />

      <Route path="/inventario/escopo-1/residuos-solidos/compostagem-anaerobica" exact component={AnaerobicCompost} isPrivate />
      <Route path="/inventario/escopo-1/residuos-solidos/relato-de-emissoes" exact component={EmissionsReport} isPrivate />
      <Route path="/inventario/escopo-1/residuos-solidos/residuos-aterrados" exact component={LandfilledWaste} isPrivate />
      <Route path="/inventario/escopo-1/residuos-solidos/coprocessamento" exact component={Coprocessing} isPrivate />
      <Route path="/inventario/escopo-1/residuos-solidos/incineracao" exact component={Incineration} isPrivate />
      <Route path="/inventario/escopo-1/residuos-solidos" exact component={SolidWaste} isPrivate />

      <Route path="/inventario/escopo-1" exact component={InventoryScope} isPrivate /> 

      <Route path="/inventario/escopo-2/compra-de-energia-termica" exact component={PurchaseThermalEnergy} isPrivate />
      <Route path="/inventario/escopo-2/eletricidade-esc-compra" exact component={ElectricityPurchaseChoice} isPrivate />
      <Route path="/inventario/escopo-2/eletricidade-localizacao" exact component={ElectricityLocation} isPrivate />
      <Route path="/inventario/escopo-2/perdas-esc-compra" exact component={TedLossesPurchaseChoice} isPrivate />
      <Route path="/inventario/escopo-2/perdas-localizacao" exact component={TedLossesLocation} isPrivate />

      <Route path="/inventario/escopo-2" exact component={InventoryScope} isPrivate />

      <Route path="/inventario/escopo-3/categorias-escopo-3" exact component={ScopeCategories3} isPrivate />

      <Route path="/inventario/escopo-3/deslocamento-casa-trabalho/veiculos-particulares" exact component={PrivateVehicles} isPrivate />
      <Route path="/inventario/escopo-3/deslocamento-casa-trabalho/transporte-publico" exact component={PublicTransport} isPrivate />
      <Route path="/inventario/escopo-3/deslocamento-casa-trabalho/home-office" exact component={HomeWorkHomeOffice} isPrivate />
      <Route path="/inventario/escopo-3/deslocamento-casa-trabalho" exact component={HomeWorkDisplacement} isPrivate />

      <Route path="/inventario/escopo-3/efluentes-gerados/emissoes-totais" exact component={EmissionsTotalsEffluents} isPrivate />
      <Route path="/inventario/escopo-3/efluentes-gerados/domesticos" exact component={EffluentsDomestic} isPrivate />
      <Route path="/inventario/escopo-3/efluentes-gerados/industriais" exact component={EffluentsGeneratedOperation} isPrivate />
      <Route path="/inventario/escopo-3/efluentes-gerados" exact component={EffluentsGenerated} isPrivate />

      <Route path="/inventario/escopo-3/residuos-solidos-gerados/compostagem-anaerobica" exact component={AnaerobicCompost3} isPrivate />
      <Route path="/inventario/escopo-3/residuos-solidos-gerados/residuos-aterrados" exact component={LandfilledWaste3} isPrivate />
      <Route path="/inventario/escopo-3/residuos-solidos-gerados/coprocessamento" exact component={Coprocessing3} isPrivate />
      <Route path="/inventario/escopo-3/residuos-solidos-gerados/incineracao" exact component={Incineration3} isPrivate />
      <Route path="/inventario/escopo-3/residuos-solidos-gerados" exact component={SolidWasteOperation} isPrivate />

      <Route path="/inventario/escopo-3/transp-distribuicao-downstream/transporte-rodoviario" exact component={TddRoadTransport} isPrivate />
      <Route path="/inventario/escopo-3/transp-distribuicao-downstream/transporte-hidroviario" exact component={TddWaterTransport} isPrivate />
      <Route path="/inventario/escopo-3/transp-distribuicao-downstream/transporte-aereo" exact component={TddAirTransport} isPrivate />
      <Route path="/inventario/escopo-3/transp-distribuicao-downstream" exact component={TransportDistrDownstream} isPrivate />

      <Route path="/inventario/escopo-3/transp-distribuicao-upstream/transporte-hidroviario" exact component={TduWaterTransport} isPrivate />
      <Route path="/inventario/escopo-3/transp-distribuicao-upstream/transporte-rodoviario" exact component={TduRoadTransport}isPrivate />
      <Route path="/inventario/escopo-3/transp-distribuicao-upstream/transporte-aereo" exact component={TduAirTransport} isPrivate />
      <Route path="/inventario/escopo-3/transp-distribuicao-upstream" exact component={TransportDistrUpstream} isPrivate />

      <Route path="/inventario/escopo-3/viagens-a-negocios/viagens-em-aeronaves" exact component={AirTrip} isPrivate />
      <Route path="/inventario/escopo-3/viagens-a-negocios/viagens-em-trens-e-metro" exact component={TrainTrip} isPrivate />
      <Route path="/inventario/escopo-3/viagens-a-negocios/viagens-em-onibus" exact component={BusTrip} isPrivate />
      <Route path="/inventario/escopo-3/viagens-a-negocios/viagens-em-automoveis" exact component={CarTrip} isPrivate />
      <Route path="/inventario/escopo-3/viagens-a-negocios" exact component={BusinessTrips} isPrivate />
      
      <Route path="/inventario/escopo-3" exact component={InventoryScope} isPrivate />

      <Route path="/inventario" exact component={Inventory} isPrivate />

      {/* <Route path="/resumo" exact component={Resume} isPrivate />  */}
      <Route path="/rpe" exact component={RpeResume} isPrivate />
      
      <Route path="/curva-macc/projetos/edicao" exact component={ProjectEdit} isPrivate />
      <Route path="/curva-macc/projetos" exact component={Project} isPrivate />
      <Route path="/curva-macc" exact component={MaccCurve} isPrivate />

      {/* <Route path="/indicadores" exact component={Dashboard} isPrivate /> */}

      <Route path="/email" exact component={Mail} isPrivate />

      <Route path="/termos-de-uso" exact component={TermsOfUse} isPrivate />

      <Route path="/empresa/inclusao" exact component={CompanyRegister} isPrivate />
      <Route path="/empresa/escopo" exact component={CompanyScope} isPrivate />
      <Route path="/empresa/edicao" exact component={CompanyEdit} isPrivate />
      <Route path="/empresa/info" exact component={CompanyInfo} isPrivate />
      <Route path="/empresa" exact component={Company} isPrivate />

      <Route path="/usuario/inclusao" exact component={UserInclude} isPrivate />
      <Route path="/usuario/edicao" exact component={UserEdit} isPrivate />
      <Route path="/usuario" exact component={User} isPrivate />

      <Route path="/auditoria" exact component={Audit} isPrivate />

      <Route path="/aeroporto/edicao" exact component={AirportEdit} isPrivate />
      <Route path="/aeroporto" exact component={Airport} isPrivate />

      <Route path="/setor/edicao" exact component={ActivityEdit} isPrivate />
      <Route path="/setor" exact component={Activity} isPrivate />

      <Route path="/caracteristicas-climaticas/edicao" exact component={ClimaticCharacteristicEdit} isPrivate />
      <Route path="/caracteristicas-climaticas" exact component={ClimaticCharacteristic} isPrivate />

      <Route path="/combustivel/fatores-mensais" exact component={FuelMonthly} isPrivate />
      <Route path="/combustivel/fatores-n2o" exact component={FuelFatorsN2o} isPrivate />
      <Route path="/combustivel/fatores-ch4" exact component={FuelFatorsCh4} isPrivate />
      <Route path="/combustivel/edicao" exact component={FuelEdit} isPrivate />
      <Route path="/combustivel/add" exact component={FuelAdd} isPrivate />
      <Route path="/combustivel" exact component={Fuel} isPrivate />

      <Route path="/combustivel-particular/edicao" exact component={VehicleFuelEdit} isPrivate />
      <Route path="/combustivel-particular" exact component={VehicleFuel} isPrivate />

      <Route path="/dados-sazonais/edicao" exact component={SeasonalDataEdit} isPrivate />
      <Route path="/dados-sazonais" exact component={SeasonalData} isPrivate />

      <Route path="/escopo-inventario/edicao" exact component={ScopeEdit} isPrivate />
      <Route path="/escopo-inventario" exact component={Scope} isPrivate />

      <Route path="/gas/edicao" exact component={GasEdit} isPrivate />
      <Route path="/gas" exact component={Gas} isPrivate />

      <Route path="/home-office/edicao" exact component={HomeOfficeEdit} isPrivate />
      <Route path="/home-office" exact component={HomeOffice} isPrivate />

      <Route path="/organismo/edicao" exact component={OrganismEdit} isPrivate />
      <Route path="/organismo" exact component={Organism} isPrivate />

      <Route path="/refrigeracao/edicao" exact component={EquipamentEdit} isPrivate />
      <Route path="/refrigeracao" exact component={Equipament} isPrivate />

      <Route path="/setor-combustao/edicao" exact component={SectorEdit} isPrivate />
      <Route path="/setor-combustao" exact component={Sector} isPrivate />

      <Route path="/conssessionaria-trem/edicao" exact component={TrainCompaniesEdit} isPrivate />
      <Route path="/conssessionaria-trem" exact component={TrainCompanies} isPrivate />

      <Route path="/tipo-chamado/edicao" exact component={TicketTypeEdit} isPrivate />
      <Route path="/tipo-chamado" exact component={TicketType} isPrivate />

      <Route path="/tipo-energia/edicao" exact component={EnergyTypeEdit} isPrivate />
      <Route path="/tipo-energia" exact component={EnergyType} isPrivate />

      <Route path="/tipo-frota/fatores-ano" exact component={CarFleetYear} isPrivate />
      <Route path="/tipo-frota/edicao" exact component={CarFleetEdit} isPrivate />
      <Route path="/tipo-frota" exact component={CarFleet} isPrivate />

      <Route path="/tipo-tratamento/edicao" exact component={TreatmentTypeEdit} isPrivate />
      <Route path="/tipo-tratamento" exact component={TreatmentType} isPrivate />

      <Route path="/ipcc-efluentes" exact component={IPCCEffluents} isPrivate />
      <Route path="/ipcc-efluentes/edicao" exact component={IPCCEffluentsEdit} isPrivate />

      <Route path="/ipcc-efluentes-2006" exact component={IPCCEffluents2006} isPrivate />
      <Route path="/ipcc-efluentes-2006/edicao" exact component={IPCCEffluents2006Edit} isPrivate />
      
      <Route path="/tipo-transporte/edicao" exact component={TransportTypeEdit} isPrivate />
      <Route path="/tipo-transporte" exact component={TransportType} isPrivate />

      <Route path="/topico-ajuda/edicao" exact component={HelpTopicEdit} isPrivate />
      <Route path="/topico-ajuda" exact component={HelpTopic} isPrivate />

      <Route path="/unidade/edicao" exact component={UnityEdit} isPrivate />
      <Route path="/unidade" exact component={Unity} isPrivate />

      <Route path="/ajuda" exact component={Help} isPrivate />

    </Switch>
  )
}
