import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../../components/Button/styles";
import { DescriptionText } from "../../../../components/DescriptionText";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import InfoButtonMin from '../../../../assets/icons/info-sign.png';
import { Container } from "./styles";
import { formatPrice, isValidCpf, toNumberValues, transformCPF, transformOnlyNumbers } from "../../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import unityService from "../../../../services/unityService";
import { toast } from "react-hot-toast";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Inputs {
  responsible_name: string;
  responsible_email: string;
  responsible_cpf: string;
  reason_year: string;
  reason: string;
  inventory_type: number;
  number_of_employees: string;
}

export const InventoryBasicData = () => {
  const history = useHistory();

  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sSize, setSSize] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [units, setUnits] = useState<any>([]);
  const [invalidCpf, setInvalidCpf] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [products, setProducts] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emptyProduct, setEmptyProduct] = useState(false);
  const [anonymize, setAnonymize] = useState(false);
  const [onlyView, setOnlyView] = useState(false);
  const [infoPartial, setInfoPartial] = useState<string | undefined>();
  const [infoComplete, setInfoComplete] = useState<string | undefined>();
  const [remainingChars, setRemainingChars] = useState<Partial<Record<keyof Inputs, number>>>({
    reason_year: 200,
    reason: 200,
  });
  

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')

      setInfoPartial("Não contabiliza todas as categorias de Escopo 1 e 2 que se apliquem à organização.")
      setInfoComplete("Contabiliza todas as categorias de Escopo 1 e 2 que se apliquem à organização. O escopo 3 é um registro de relato opcional.")
    }else{
      history.push({
        pathname: '/inventario',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>();

  useEffect(() => {
    console.log(errors)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [errors]);

   const onSubmit: SubmitHandler<any> = async (formData) => {
    setEmptyProduct(false);
    setInvalidCpf(false);

    if (formData) {
      if (isValidCpf(formData.responsible_cpf)) {
        const prodFiltereds = products.filter(function (a: any) {
          return true;
        });

        const itens: any[] = prodFiltereds.map((product: any, index: number) => {
          return {
            product: formData.product[index]?.product || product.product,
            amount: parseInt(formData.product[index]?.amount || product.amount),
            unity: formData.product[index]?.unity || product.unity,
          };
        });

        const invoicing = parseFloat(
          formData.invoicing.replaceAll(".", "").replace(",", ".")
        );
        const building_area = parseFloat(
          formData.building_area.replaceAll(".", "").replace(",", ".")
        );

        const item = {
          reason: formData.reason,
          reason_year: formData.reason_year,
          inventory_type: formData.inventory_type,
          responsible_name: formData.responsible_name,
          responsible_email: formData.responsible_email,
          responsible_office: formData.responsible_office,
          invoicing: isNaN(invoicing) ? 0 : invoicing,
          building_area: isNaN(building_area) ? 0 : building_area,
          number_of_products: parseInt(formData.number_of_products) || 0,
          number_of_projects: parseInt(formData.number_of_projects) || 0,
          number_of_employees: parseInt(formData.number_of_employees) || 0,
          responsible_cpf: transformOnlyNumbers(formData.responsible_cpf) || undefined,
          products: itens,
        };

        const result = await inventoryService.updateBasicDataId(
          inventorySelected.company.id,
          inventorySelected.year,
          item
        );

        if (result && !result.error) {
          history.goBack();

          reset();
          setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!");
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        setInvalidCpf(true);
      }
    }
  };

  const formatCurrency = (value: number): string => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  
  

  async function getInventorysById(company_id: number, year: number) {
    const units = await unityService.getByParams({ limit: 9000, page: 1 });

    setUnits(units?.itens);

    const inventory = await inventoryService.getById(company_id, year);

    let responsible_cpf = inventory?.responsible_cpf;
    let responsible_name = inventory?.responsible_name;
    let responsible_email = inventory?.responsible_email;
    let responsible_office = inventory?.responsible_office;

    if (
      user.profile === "admin" ||
      user.profile === "analyst" ||
      user.profile === "sinergia"
    ) {
      responsible_cpf =
        !inventory?.responsible_cpf ? user.cpf : responsible_cpf;
      responsible_name =
        !inventory?.responsible_name ? user.name : responsible_name;
      responsible_email =
        !inventory?.responsible_email ? user.mail : responsible_email;
      responsible_office =
        !inventory?.responsible_office ? user.office : responsible_office;
    }

    setValue("reason", inventory?.reason);
    setValue("responsible_name", inventory?.responsible_name);
    setValue("responsible_email", inventory?.responsible_email);
    setValue("reason_year", inventory?.reason_year);
    setValue("responsible_office", responsible_office);
    setValue("responsible_cpf", transformCPF(responsible_cpf));
    setValue("number_of_products", inventory?.number_of_products);
    setValue("number_of_projects", inventory?.number_of_projects);
    setValue("number_of_employees", inventory?.number_of_employees);
    setValue(
      "inventory_type",
      inventory?.inventory_type ? inventory?.inventory_type.toString() : ""
    );

    // Formatação para exibição no formulário
    setValue(
      "invoicing",
      formatCurrency(Number(inventory.invoicing || 0))
    );
    setValue(
      "building_area",
      formatCurrency(Number(inventory.building_area || 0))
    );

    if (inventory?.products) {
      setProducts(
        inventory?.products.map((item: any, index: number) => {
          setValue(`product.${index}.product`, item.product);
          setValue(`product.${index}.amount`, item.amount);
          setValue(`product.${index}.unity`, item.unity);

          return item;
        })
      );
    }

    setInventorySelected(inventory);
  }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onAddProduct = () => {
    const item = {product: '', amount: undefined, unity: undefined}
    const index = products.length 
    const itens = products

    itens.push(item)

    setValue(`product.${index}.product`, item.product)
    setValue(`product.${index}.amount`, item.amount)
    setValue(`product.${index}.unity`, item.unity)

    setProducts(itens.filter(function(a:any) { 
      return true
    }))

    setEmptyProduct(false)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDeleteProduct = (index:number) => {
    const itens = products

    delete itens[index];

    setProducts(itens.map((item: any, index: number) => {
      setValue(`product.${index}.product`, item.product)
      setValue(`product.${index}.amount`, item.amount)
      setValue(`product.${index}.unity`, item.unity)

      return item;
    }));

    setSSize(itens.length)
    setEmptyProduct(false)
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, "");
  
    // Formata para o padrão brasileiro (com vírgula como separador decimal)
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  
    e.currentTarget.value = value; // Atualiza o valor formatado no campo
    return value;
  }
  

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const remaining = 200 - value.length;
    setRemainingChars((prevChars) => ({
      ...prevChars,
      [name as keyof Inputs]: remaining,
    }));
    setValue(name as keyof Inputs, value);
  };

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "block"}} >
            <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
            <TitleText level={4} title="Dados do inventário"  />
          </div>
          
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", paddingLeft: 20, paddingRight: 20}} >

          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }} >
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Responsável
              </label>
              <input disabled={onlyView}
                {...register("responsible_name", { required: true })}
              />
              {errors.responsible_name && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }} >
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                E-mail
              </label>
              <input disabled={anonymize || onlyView}
                {...register("responsible_email", { required: true })}
                onChange={(event) => {
                  event.target.value = event.target.value.toLowerCase();
                }}
              />
              {errors.responsible_email && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }} >
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                CPF
              </label>
              <input disabled={anonymize || onlyView}
                {...register("responsible_cpf", { required: true })}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = transformCPF(value);
                }}
              />
              {errors.responsible_cpf && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}

              {invalidCpf && (
                <span className='requiredLabel' >CPF inválido.</span>
              )}
            </div>
          </div> 

          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Tipo de Inventário
          </label>

          <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'start', alignItems: 'start', marginTop: 5}}>
            <div style={{ display: "flex", flexDirection: "row"}}>
              <input type="radio" value="1" disabled={onlyView} style={{width: 20, marginLeft: 0,  marginRight: 10}}
                  {...register("inventory_type", { required: true })}
                  onChange={(event) => {
                      setValue("inventory_type", event.target.value);
                  }} /> 

              <span style={{ fontSize: 16, color: "#31363F", marginTop: 12,
                  fontFamily: "Poppins, sans-serif", opacity: 0.9 }}>
                Parcial
              </span>

              <div className="tooltip" style={{margin: 8}}>
                <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                <span className="tooltiptext">{infoPartial}</span>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row"}}>
              <input type="radio" value="2" disabled={onlyView} style={{width: 20, marginLeft: 0,  marginRight: 10}}
                  {...register("inventory_type", { required: true })}
                  onChange={(event) => {
                      setValue("inventory_type", event.target.value);
                  }} /> 

              <span style={{ fontSize: 16, color: "#31363F", marginTop: 12,
                  fontFamily: "Poppins, sans-serif", opacity: 0.9 }}>
                Completo
              </span>

              <div className="tooltip" style={{margin: 8}}>
                <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                <span className="tooltiptext">{infoComplete}</span>
              </div>
            </div>
          </div>

          {errors.inventory_type && (
              <span style={{ color: "#E33F3F", marginTop: 10 }}>
                  Esse campo é obrigatório
              </span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
            Motivo pelo qual decidiu elaborar o IGEE ({remainingChars.reason})
          </label>

          <input disabled={onlyView}
            {...register("reason", { required: true })}
            onChange={handleInputChange}
          />

          {errors.reason && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
            Motivo da escolha do ano base ({remainingChars.reason_year})
          </label>

          <input disabled={onlyView}
            {...register("reason_year", { required: true })}
            onChange={handleInputChange}
          />

          {errors.reason_year && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <div style={{ padding: 10 }}/>

          <TitleText level={4} title="Relate abaixo os dados de sua instituição no ano inventáriado. "  />
          <DescriptionText title="Os dados preenchidos serão utilizados para a confecção de indicadores e gráficos."  />

          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "34%" }} >
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Área construída (m²)
              </label>

              <input disabled={onlyView}
                {...register("building_area")}
                onChange={(event) => {
                  setValue("building_area", currency(event));
                }}
              />

              {errors.building_area && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }} >
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Número de funcionários
              </label>

              <input disabled={onlyView}
                {...register("number_of_employees")}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = toNumberValues(value);
                }}
              />

              {errors.number_of_employees && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>


            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }} >
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Faturamento
              </label>

              <input disabled={onlyView}
                {...register("invoicing")}
                onChange={(event) => {
                  setValue("invoicing", currency(event));
                }}
              />

              {errors.invoicing && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div> 

          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "34%" }} >
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Número de projetos
              </label>

              <input disabled={onlyView}
                {...register("number_of_projects")}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = toNumberValues(value);
                }}
              />

              {errors.number_of_projects && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }} >
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Número de produtos
              </label>

              <input disabled={onlyView}
                {...register("number_of_products")}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = toNumberValues(value);
                }}
              />

              {errors.number_of_products && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }} />

          </div> 
        </div>
      </form>
    </Container>
  );
};
