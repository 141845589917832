import { NavLink } from "react-router-dom";
import { IMenu } from "../../typings/Menu";
import { links } from "./links";
import LogoutIcon from "../../assets/icons/log-out.svg";
import HelpIcon from "../../assets/icons/help-menu.svg";
import useAuth from "../../hooks/useAuth";
import { Container } from "./styles";
import User from "../../assets/icons/no-userphoto.svg";
import Logo from "../../assets/images/logo.svg";
import LogoMenu from "../../assets/images/sinergiaLogo.png";
import LogoOca from "../../assets/images/logo-oca-azul.png";
import { linksSinergia } from "./linksSinergia";
import { useHistory } from "react-router-dom";
import { linksLocked } from "./linksLocked";
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsList } from "react-icons/bs";
import theme from "../../theme";
import { useMenu } from "../../contexts/menuContext";
import { ButtonToggleMenu } from "../ButtonToggleMenu/index"

export const Menu = () => {
  const history = useHistory();
  const { logout, user } = useAuth();
  const { isMenuOpen, toggleMenu } = useMenu();

  return (
    <Container width={isMenuOpen ? "280px" : "60px"} className="menu menu-container">

      {/* User infos */}
      {isMenuOpen && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: 25,
            marginTop: 10,
            marginRight: 25,
            marginBottom: 10,
          }}
        >
          {/* <img src={User} alt="userImage" width="42" /> */}
          <img src={LogoOca} alt="userImage" width="40" />
  
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 10,
              marginTop: 5,
            }}
          >
            <span className="user-name">{user?.name}</span>
            <span className="user-mail">{user?.mail}</span>
          </div>
        </div>
      )}

      <ButtonToggleMenu />
      {!isMenuOpen && (
        <NavLink to={"/inventario"}>
          <img src={LogoOca} alt="logo-" className="logo-menu" width="50" />
        </NavLink>
      )}


      <div
      className="custom-scrollbar menu-container"
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: isMenuOpen ? "calc(100vh - 250px)" : "calc(100vh - 150px)",
          overflow: "auto",
        }}
      >
        <ul className="container-links">
          {user.profile !== "sinergia"  && user.is_locked === false &&
            links()
              .filter((link: IMenu) => link.active)
              .map((link: IMenu, index: number) => (
                <li key={index} className={link.path === '-' ? 'menu-divider' : ''}>
                  {link.path !== '-' && (
                    <NavLink
                      to={link.path}
                      className={`${link.path.replace("/", "")}-link link`}
                      activeClassName="active-link"
                    >
                      <div className="content-link ">
                        <img
                          src={link.icon}
                          alt={`${link.path.replace("/", "")}-link`}
                          title={link.name}
                        />
                                              
                        {isMenuOpen && ( <span className="link-text">{link.name}</span> )}

                      </div>
                    </NavLink>
                  )}
                </li>
              ))}

          {user.profile === "sinergia" && user.is_locked === false &&
            linksSinergia()
              .filter((link: IMenu) => link.active)
              .map((link: IMenu, index: number) => (
                <li key={index} className={link.path === '-' ? 'menu-divider' : ''}>
                  {link.path !== '-' && (
                    <NavLink
                      to={link.path}
                      className={`${link.path.replace("/", "")}-link link`}
                      activeClassName="active-link"
                    >
                      <div className="content-link">
                        <img
                          src={link.icon}
                          alt={`${link.path.replace("/", "")}-link`}
                          title={link.name}
                        />

                        {isMenuOpen && ( <span className="link-text">{link.name}</span> )}
                      
                      </div>

                    </NavLink>
                  )}
                </li>
              ))
          }

          {user.is_locked === true &&
            linksLocked()
              .filter((link: IMenu) => link.active)
              .map((link: IMenu, index: number) => (
                <li key={index} className={link.path === '-' ? 'menu-divider' : ''}>
                  {link.path !== '-' && (
                    <NavLink
                      to={link.path}
                      className={`${link.path.replace("/", "")}-link link`}
                      activeClassName="active-link"
                    >
                      <div className="content-link">
                        <img
                          src={link.icon}
                          alt={`${link.path.replace("/", "")}-link`}
                          title={link.name}
                        />

                        {isMenuOpen && ( <span className="link-text">{link.name}</span> )}

                      </div>
                    </NavLink>
                  )}
                </li>
              ))}

          {/* Logo Empresa */}
          {isMenuOpen && (
            <>
              <img src={Logo} alt="logo-" className="logo-image" width="100" />
              <img src={LogoMenu} alt="logo-" style={{ display: "none" }} />
            </>
          )}


          {/* Links Ajuda e Sair */}
          <li>
            <div
              className="content-link containt-help"
              onClick={() => history.push('/ajuda')}
            >
              <img src={HelpIcon} alt="-help" title="Ajuda" />

              {isMenuOpen && ( <span className="link-text logout-text">Ajuda</span> )}

              
            </div>

            <div
              className="content-link containt-logout"
              onClick={() => logout(true)}
            >
              <img src={LogoutIcon} alt="log-out" title="Sair da conta" />

              {isMenuOpen && ( <span className="link-text logout-text">Sair da conta</span> )}

              
            </div>
          </li>
        </ul>
      </div>
    </Container>
  );
};
