import styled from "styled-components";

interface ToggleMenuProps {
    isOpen: boolean;
}

export const ButtonToggleMenuStyle = styled.div<ToggleMenuProps>`
    cursor: pointer;

    /* Estilos icones menu */

    .collapse-menu {
        position: absolute;
        top: 40px;
        left: 270px;
        box-shadow: 0px 0px 3px 3px #d0dae7c5;
        border-radius: 0px 50px 50px 0px;
        padding: 5px 5px 5px 2px;
        width: 12px;
    }

    .expand-menu {
        position: absolute;
        top: 40px;
        left: 60px;
        box-shadow: 0px 0px 3px 3px #d0dae7c5;
        border-radius: 0px 50px 50px 0px;
        padding: 5px 5px 5px 2px;
        width: 12px;
    }

`;